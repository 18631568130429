import { Suspense } from "react"
import { LoginLoader } from "../layout/theme/fallback"
import { AuthBody } from "../layout/theme/global"
import { Login } from "../features"

interface AuthPageProps {
    page: 'login'
}
const AuthPage = ({
    page
}: AuthPageProps) => {
    return (
        <Suspense fallback={<LoginLoader/>}>
            <AuthBody>
                { page === 'login' && <Login/> }
            </AuthBody>
        </Suspense>
    )
}

export default AuthPage