import { Suspense } from 'react'
import { WaitingLoader } from '../layout/theme/fallback'
import { GlobalBody } from '../layout/theme/global'
import { AdminDashboard } from '../features'

interface DashboardPageInterface {
    page: 'admin'
}

const DashboardPage = ({
    page
}: DashboardPageInterface) => {
    return (
        <Suspense fallback={<WaitingLoader/>}>
            <GlobalBody>
                { page === 'admin' && <AdminDashboard /> }
            </GlobalBody>
        </Suspense>
    )
}

export default DashboardPage