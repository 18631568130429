import { Logo } from '@bigbroindia/logo'
import { Card, CardBody, Col, Row } from '@nanobits/react-ui'
import logo from '../../assets/static/logo.svg'
import NameForm from './Name'
import { useAppAuth } from '../../app/hook'
import VerificationForm from './Verification'

const AccountSetup = () => {

    const auth = useAppAuth()

    const showNameUpdateForm = auth?.user.lastname === null
    const showVerificationForm = !showNameUpdateForm && (auth?.user.emailVerifiedAt === null || auth?.user.phoneVerifiedAt === null)

    return (
        <Row className={'justify-content-center'}>
            <Col md={4} sm={12}>
                <Card className={'p-4 border'}>
                    <Logo image={logo} className={'nano-app-auth-logo'} height={100} />
                    <CardBody className={'p-0 mt-3'}>
                        { showNameUpdateForm && <NameForm /> }
                        { showVerificationForm && <VerificationForm authMode={auth.user.authMode} /> }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default AccountSetup