import React, { forwardRef } from 'react'
import { Slide, ToastContainer } from 'react-toastify'

import { Div } from '@nanobits/react-ui'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../fallback'
import GlobalFooter from './Footer'
import GlobalHeader from './Header'
import GlobalSidebar from './Sidebar'

interface GlobalBodyProps {
    children: React.ReactNode
}

const GlobalBody = forwardRef<HTMLDivElement, GlobalBodyProps>((
  {
    children
  },
  ref
) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Div ref={ref}>
        <GlobalSidebar/>
        <Div className={'nano-wrapper d-flex flex-column min-vh-100 bg-light'}>
          <GlobalHeader/>
          <Div className={'body flex-grow-1 px-3'}>
            {children}
          </Div>
          <GlobalFooter/>
        </Div>
        <ToastContainer autoClose={5000} transition={Slide}/>
      </Div>
    </ErrorBoundary>
  )
})

export default GlobalBody
