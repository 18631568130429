import { ServerErrorLoader } from '../layout/theme/fallback'


const ServerErrorPage = () => {
    return (
        <ServerErrorLoader location={'/'} timer={10000} />
    )
}


export default ServerErrorPage