import axios, { AxiosInstance, AxiosError, AxiosResponse, InternalAxiosRequestConfig, RawAxiosRequestHeaders, AxiosProgressEvent } from 'axios'
import { StorageService } from '@bigbroindia/barebone'

class AxiosService {

    protected static instance: AxiosInstance

    private static get connect() {
        this.init()
        return this.instance
    }

    private static async init() {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: this.getHttpHeaders(),
            responseType: 'json'
        })
        this.instance.interceptors.request.use(
            (request: InternalAxiosRequestConfig) => this.requestHandle(request),
            (error: AxiosError) => error
        )
        this.instance.interceptors.response.use(
            (response: AxiosResponse) => this.responseHandle(response),
            (error: AxiosError) => this.errorHandle(error)
        )
    }

    public static async get(url: string, filters?: unknown) {
        try {
            const path = this.sanitize(url, filters)
            const success = await this.connect.get(path)
            return await success.data
        } catch (error) {
            throw error
        }
    }

    public static async post(url: string, data: unknown) {
        try {
            const success = await this.connect.post(url, data)
            return await success.data
        } catch (error) {
            throw error
        }
    }

    public static async put(url: string, data: unknown) {
        try {
            const success = await this.connect.put(url, data)
            return await success.data
        } catch (error) {
            throw error
        }
    }

    public static async patch(url: string) {
        try {
            const path = this.sanitize(url)
            const success = await this.connect.patch(path)
            return await success.data
        } catch (error) {
            throw error
        }
    }

    public static async destroy(url: string) {
        try {
            const path = this.sanitize(url)
            const success = await this.connect.delete(path)
            return await success.data
        } catch (error) {
            throw error
        }
    }

    public static async upload(url: string, data: FormData) {
        try {
            const success = await this.connect.post(url, data, {
                headers: this.getHttpFormHeaders(),
                maxContentLength: 1000000000,
                maxBodyLength: 1000000000,
                timeout: 0
            })
            return success.data
        } catch (error) {
            throw error
        }
    }

    public static async uploadWithProgress(url: string, data: FormData, onUploadProgress: (event: AxiosProgressEvent) => void) {
        try {
            const success = await this.connect.post(url, data, {
                headers: this.getHttpFormHeaders(),
                onUploadProgress,
                maxContentLength: 1000000000,
                maxBodyLength: 1000000000,
                timeout: 0
            })
            return success.data
        } catch (error) {
            throw error
        }
    }

    private static async responseHandle(response: AxiosResponse) {
        if (response.statusText === 'OK' && response.config.url === '/') await this.init()
        return response
    }

    private static async requestHandle(request: InternalAxiosRequestConfig) {
        const endpoint = process.env.REACT_APP_ADMIN_HOST
        const host = window.location.host
        const subdomain = host.split('.')[0] === 'www' ? host.split('.')[1] : host.split('.')[0]
        const baseUrl = (subdomain !== host) ? (subdomain ? endpoint?.replace('@@subdomain@@', subdomain) : null) : null
        if (baseUrl) request.baseURL = baseUrl
        return request
    }

    private static async errorHandle(error: AxiosError) {
        if (error === undefined || error.message === 'Network Error') {
            window.location.href = '/server-error'
        } else {
            const originalRequest = error.config as InternalAxiosRequestConfig
            const response = error.response as AxiosResponse
            if (response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_URL}/a/login/refresh`) {
                this.clearStorage()
                return Promise.reject(error)
            }
            if (response.status === 401 && (response.statusText === 'Unauthorized' || response.data.error === 'Unauthorized') && response.data.message === 'Expired token') {
                const refreshToken = this.getRToken()
                if (!refreshToken) {
                    this.clearStorage()
                    return Promise.reject(error)
                }
                return this.instance.post('/a/login/refresh', { refresh: refreshToken }).then((response: any) => {
                    this.setToken(response.data.token)
                    this.setRToken(response.data.refresh)
                    this.instance.defaults.headers['Authorization'] = `${response.data.token}`
                    originalRequest.headers['Authorization'] = `${response.data.token}`
                    return this.instance(originalRequest)
                }).catch((error: Error) => {
                    this.clearStorage()
                    return Promise.reject(error)
                })
            }
            if (response.status === 401 && (response.data.message === 'Missing authentication' || response.data.message === 'Invalid credentials' || response.data.message === 'Refresh token validity expired.')) {
                this.clearStorage()
                return Promise.reject(error)
            }
        }
        return Promise.reject(error)
    }

    private static getHttpHeaders() {
        const token = this.getToken()
        const headers: RawAxiosRequestHeaders = { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        if (token) headers['Authorization'] = `${token}`
        return headers
    }

    private static getHttpFormHeaders() {
        const token = this.getToken()
        const headers: RawAxiosRequestHeaders = { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }
        if (token) headers['Authorization'] = `${token}`
        return headers
    }

    private static getToken() {
        return StorageService.getItem('token')
    }

    private static setToken(token: string) {
        return StorageService.setItem('token', token)
    }

    private static getRToken() {
        const token = StorageService.getItem('refresh')
        if (token) {
            const tokenParts = JSON.parse(atob(token.split('.')[1]))
            const now = Math.ceil(Date.now() / 1000)
            if (tokenParts.exp > now) {
                return token
            }
        }
        return token
    }

    private static setRToken(token: string) {
        return StorageService.setItem('refresh', token)
    }

    private static clearStorage() {
        return StorageService.clearAll()
    }

    private static sanitize(path: string, filters?: any) {
        const newPath = this.sanitizeObject(path, filters)
        const queryStringArray = newPath.split('?')
        const newString = [] as any
        if (queryStringArray.length === 2) {
            const query = queryStringArray[1]
            const queryArray = query.split('&')
            queryArray.forEach(query => {
                const keyPair = query.split('=')
                if (keyPair.length === 2 && keyPair[1] !== undefined && keyPair[1] !== '' && keyPair[1] !== 'undefined') {
                    newString.push(keyPair.join('='))
                }
            })
        }
        return queryStringArray[0] + (newString.length > 0 ? '?' + newString.join('&') : '')
    }

    private static sanitizeObject(url: string, filters: any) {
        if (filters && Object.keys(filters).length > 0) {
            let filterUrl = ''
            for (const filterKey in filters) {
                if (filters[filterKey] !== undefined && filters[filterKey] !== '' && !Array.isArray(filters[filterKey])) {
                    filterUrl += `&${filterKey}=${filters[filterKey]}`
                }
                if (filters[filterKey] instanceof Array) {
                    filterUrl += `&${this.arrayInApi(filters[filterKey], filterKey)}`
                }
            }
            return `${url}${url.includes('?') ? filterUrl : filterUrl.replace('&', '?')}`
        }
        return url
    }

    private static arrayInApi(items: any[], key: string) {
        if (items.length > 1) {
            return `${key}=${items.join(`&${key}=`)}`
        }
        return `${key}=${items[0]}`
    }
}

export default AxiosService