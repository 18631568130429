import { SelectInputItemInterface } from '../../@types'
import { formatDateTime, getStatus } from '@bigbroindia/helper'
import { StateAPIDropdownAPIMAPResponse, StateAPIMAPResponse, StateAPIResponse, StateDropdownAPIResponse, StateForm, StateListAPIMAPResponse, StateListAPIResponse } from './model'

const stateListMap = (data: StateListAPIResponse): StateListAPIMAPResponse => {
  const { list, meta } = data
  const mappedList = list.map((item: StateAPIResponse, index: number) => {
    return {
      _id: item.id,
      serial: (index+1) + ((meta.page-1) * meta.per_page),
      name: item.name,
      country: item.country.name,
      status: getStatus(item.status),
      updateOn: formatDateTime(new Date(item.updatedAt))
    } as StateAPIMAPResponse
  })
  return {
    list: mappedList,
    meta
  }
}

const stateDropdownMap = (data: StateDropdownAPIResponse): StateAPIDropdownAPIMAPResponse => {
  const {items} = data
  const mappedItems = items.map((item) => {
    return {
      value: String(item.id),
      label: item.name
    } as SelectInputItemInterface
  })
  return {
    items: mappedItems
  }
}

const stateFormMap = (data: StateAPIResponse): StateForm => {
  return {
    country_id: String(data.countryId) ?? null,
    name: data.name ?? null
  }
}

export {
  stateListMap,
  stateDropdownMap,
  stateFormMap
}