import { Form, InputGroup } from "@nanobits/react-ui"
import { NTextInput } from "../../monorep"
import { useEffect, useState } from "react"
import { NButton } from "@nanobits/react-components"
import { useAuthStore } from "../../stores"
import { OtpResndAPIRequest, OtpSendAPIRequest, VerifyOtpAPIRequest } from "../../models/auth/model"
import { unixToSeconds } from "../../helpers/shorthand"

interface VerificationFormInterface {
  authMode: 'email' | 'mobile'
}

const VerificationForm = ({
  authMode
}: VerificationFormInterface) => {

  const authStore = useAuthStore()

  const [username, setUsername] = useState<string>('')
  const [otp, setOtp] = useState<number>()

  const [resend, setResend] = useState<number>(0)

  const invalidInput: boolean = resend > 0

  const handleSetOtpButton = () => {
    const postData: OtpSendAPIRequest = {
      username,
      mode: authMode === 'email' ? 'mobile' : 'email'
    }
    authStore.postSendOtp(postData, () => {
      if (authStore.otpPayload !== undefined) {
        setResend(unixToSeconds(authStore?.otpPayload?.resend || 0) || 0)
      }
    })
  }

  const handleResendButton = () => {
    const postData: OtpResndAPIRequest = {
      username
    }
    authStore.postResendOtp(postData, () => {
      if (authStore.otpPayload !== undefined) {
        setResend(unixToSeconds(authStore?.otpPayload?.resend || 0) || 0)
      }
    })
  }

  const handleOtpVerification = () => {
    const postData: VerifyOtpAPIRequest = {
      username,
      mode: authMode === 'email' ? 'mobile' : 'email',
      code: String(otp)
    }
    authStore.postOtpVerification(postData, () => {
      authStore.getProfile(() => {})
    })
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined
    if (resend && resend > -1) timeout = setTimeout(() => setResend(resend - 1), 1000)
    else setResend(0)
    return () => clearTimeout(timeout)
  }, [resend])

  return (
    <Form>
      <InputGroup className={'mb-3'}>
        <NTextInput
          type={authMode === 'email' ? 'tel' : 'email'}
          name={'email-mobile-input'}
          value={username}
          placeholder={`Enter ${authMode === 'email' ? 'Mobile Number' : 'Email'}`}
          onUpdate={setUsername}
        />
      </InputGroup>
      {authStore?.otpPayload?.code !== undefined && <InputGroup className={'mb-3'}>
        <NTextInput
          name={'otp'}
          type={'number'}
          value={otp}
          placeholder={'Enter OTP'}
          maxLength={6}
          onUpdate={setOtp}
        />
      </InputGroup>}
      <InputGroup className={'d-grid'}>
       { (String(otp).length !== 0 && otp !== undefined) ?  <NButton
          loading={authStore.loading}
          buttonVariant={'outline'}
          buttonText={'Verify'}
          onClick={handleOtpVerification}
        /> :  <NButton
        loading={authStore.loading}
        buttonVariant={'outline'}
        disabled={ authStore?.otpPayload?.code !== undefined ? invalidInput : false }
        buttonText={authStore?.otpPayload?.code !== undefined ? `Resend OTP ${resend  > 0 ? `in ${resend}`:''}` : 'Send OTP'}
        onClick={authStore?.otpPayload?.code !== undefined ? handleResendButton : handleSetOtpButton}
      /> }
      </InputGroup>
    </Form>
  )
}

export default VerificationForm