import { AxiosError } from 'axios'
import { AuthRepository, LoginResponse, LogoutResponse, OtpSendResponse, PasswordChangeResponse, PasswordForgetResponse, PasswordResetResponse, ProfileResponse } from './interface'
import { APIError, LoginAPIRequest, LoginAPIResponse, LoginAPIError, ProfileAPIResponse, PasswordForgetAPIRequest, PasswordForgetAPIRespone, PasswordResetAPIRequest, LogoutAPIRequest, ProfileAPIRequest, PasswordChangeAPIRequest, OtpSendAPIRequest, VerifyOtpAPIRequest, OtpResndAPIRequest } from './model'
import { Repository } from '../../@types'
import { APIService } from '@bigbroindia/barebone'
import { UserResponse } from '../user/interface'

/**
 * Retrieve user access and refresh token
 * @param {LoginAPIRequest} payload LoginAPIRequest
 * @returns {LoginResponse} Login api response
 */
async function postLogin (payload: LoginAPIRequest): Promise<LoginResponse> {
  try {
    const response: LoginAPIResponse = await APIService.post('/a/login', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as LoginAPIError
    console.error('Login api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

/**
 * Retrieve logged user profile
 * @returns {ProfileResponse} ProfileAPIResponse
 */
async function getProfile (): Promise<ProfileResponse> {
  try {
    const response: ProfileAPIResponse = await APIService.get('/a/profile')
    return {
      data: response,
      error: null
    }
  }  catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Profile get api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

/**
 * Submit user forget password request
 * @param {PasswordForgetAPIRequest} payload PasswordForgetAPIRequest
 * @returns {PasswordForgetResponse} Password forget api response
 */
async function postPasswordForget (payload: PasswordForgetAPIRequest): Promise<PasswordForgetResponse> {
  try {
    const response: PasswordForgetAPIRespone = await APIService.post('/a/login/forget', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Password forget api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

/**
 * Submit user password reset request
 * @param {PasswordResetApiRequest} payload PasswordResetApiRequest
 * @returns {PasswordResetResponse} Password reset api response
 */
async function postPasswordReset (payload: PasswordResetAPIRequest): Promise<PasswordResetResponse> {
  try {
    const response = await APIService.post('/a/login/reset', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Password reset api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

async function postLogout (payload: LogoutAPIRequest): Promise<LogoutResponse> {
  try {
    const response = await APIService.post('/a/profile/logout', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Logout api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

async function postProfile (payload: ProfileAPIRequest): Promise<ProfileResponse> {
  try {
    const response = await APIService.post('/a/profile', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Profile save api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

async function postPasswordChange(payload: PasswordChangeAPIRequest): Promise<PasswordChangeResponse> {
  try {
    const response = await APIService.post('/a/profile/password', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Password change api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

async function postOtpSend (payload: OtpSendAPIRequest): Promise<OtpSendResponse> {
  try {
    const response = await APIService.post('/a/register/send-otp', payload)
    return {
      message: response.message,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Otp send api error response', JSON.stringify(error))
    return {
      message: null,
      error: error.message
    }
  }
}

async function postOtpResend (payload: OtpResndAPIRequest): Promise <OtpSendResponse> {
  try {
    const response = await APIService.post('/a/register/resend-otp', payload)
    return {
      message: response.message,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Otp resend api error response', JSON.stringify(error))
    return {
      message: null,
      error: error.message
    }
  }
}

async function postOtpVerification (payload: VerifyOtpAPIRequest): Promise<UserResponse> {
  try {
    const response = await APIService.post('/a/register/verify-otp', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Otp verify api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

export const authRepository: Repository<AuthRepository> = {
  postLogin,
  getProfile,
  postPasswordForget,
  postPasswordReset,
  postLogout,
  postProfile,
  postPasswordChange,
  postOtpSend,
  postOtpVerification,
  postOtpResend
}