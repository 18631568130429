import { NButton } from "@nanobits/react-components"
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { useNavigate } from "react-router-dom"
import { useCountryStore, useStateStore } from "../../stores"
import { NTextInput, SelectInput } from "../../monorep"
import { useEffect, useState } from "react"
import { CountryDropdownAPIRequestFilter } from "../../models/country/model"
import { StateAPIRequest } from "../../models/state/model"

interface StateFormInterface {
    formType?: 'create' | 'update'
}

const StateForm = ({
    formType
}: StateFormInterface) => {

    const navigate = useNavigate()

    const countryStore = useCountryStore()
    const stateStore = useStateStore()

    const [country_id, setCountryId] = useState<number>()
    const [name, setName] = useState<string>()

    const handleSubmit = () => {
        const postData: StateAPIRequest = {
            country_id: Number(country_id),
            name: String(name)
        }

        if (formType === 'create') {
            stateStore.postStates(postData, () => navigate('/location/states'))
        } else {
            stateStore.putState(Number(stateStore.state.id), postData, () => navigate('/location/states'))
        }
    }

    useEffect(() => {
        if (formType === 'update' && stateStore.state.id === undefined) {
          navigate('/location/states')
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [stateStore.state.id, formType])

    useEffect(() => {
        const timeout = setTimeout(() => countryStore.getCountryDropdown({} as CountryDropdownAPIRequestFilter))
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (formType === 'update') {
          countryStore.getCountryDropdown({} as CountryDropdownAPIRequestFilter)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [formType])

    useEffect(() => {
        if (formType === 'update') {
            setCountryId(stateStore.state.countryId)
            setName(stateStore.state.name)
        }
      }, [stateStore, formType])

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader className={'bg-primary text-white'} >
                        {formType === 'create' ? 'Create State' : `Update State: ${stateStore.state.name}`}&nbsp;
                        <NButton
                            className={'float-end mx-1 btn-success text-white'}
                            size={'sm'}
                            buttonIconLeft={'fa fa-bars'}
                            onClick={() => navigate('/location/states')}
                            loading={stateStore.loading}
                        />
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={6} className={'mb-3'}>
                                <SelectInput
                                    name={'country_id'}
                                    label={'Country'}
                                    options={countryStore.dropdown}
                                    placeholder={'Select country'}
                                    onUpdate={(value) => setCountryId(Number(value))}
                                    required
                                    value={country_id}
                                />
                            </Col>
                            <Col md={6} className={'mb-3'}>
                                <NTextInput
                                    name={'name'}
                                    label={'Name'}
                                    placeholder={'Enter state name'}
                                    required
                                    value={name}
                                    onUpdate={setName}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <NButton
                            className={'text-white px-4 fw-bold float-end'}
                            color={'success'}
                            buttonText={formType === 'create' ? 'Save' : 'Update'}
                            loading={stateStore.loading}
                            onClick={handleSubmit}
                        />
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default StateForm