import { titleCase } from '@bigbroindia/helper'
import { PgAPIMap, PgAPIResponse, PgListAPIMap, PgListAPIResponse } from './model'

export const pgListMap = (data: PgListAPIResponse): PgListAPIMap => {

    const { list, meta } = data

    const mappedList = list.map((item: PgAPIResponse) => {
        return {
            id: item.id,
            name: item.projectName,
            seater: `${item.seater}`,
            rent: item.rent,
            security: item.security,
            views: item.views,
            wishlists: item.wishlists,
            city: item.city.name,
            status: titleCase(item.propertyStatus)
        } as PgAPIMap
    })

    return {
        list: mappedList,
        meta
    }
}