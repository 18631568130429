import { useNavigate } from 'react-router-dom'
import { CommonFormInterface } from '../../@types'
import { useAmenityStore } from '../../stores'
import { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from '@nanobits/react-ui'
import { NButton } from '@nanobits/react-components'
import { NTextInput } from '../../monorep'
import { AmenityAPIPayloadInterface } from '../../models/amenity/model'

const AmenityForm = ({
    form_type = 'create'
}: CommonFormInterface) => {

    const navigate = useNavigate()
    const amenityStore = useAmenityStore()

    const [name, setName] = useState<string>()

    const disabled = !name

    const handleButton = () => {
        const payload: AmenityAPIPayloadInterface = {
            name: name || '',
            category_id: 1
        }
        if (form_type === 'create') {
            amenityStore.postAmenity(payload, () => navigate('/amenities'))
        } else {
            amenityStore.updateAmenity(amenityStore.amenity.id, payload, () => navigate('/amenities'))
        }
    }

    useEffect(() => {
        if (form_type === 'update') {
            setName(amenityStore.amenity.name)
        }
    }, [form_type, amenityStore.amenity])

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader className={'bg-primary text-white'}>
                    {`${form_type === 'create' ? 'Create' : 'Update'} Amenity`}&nbsp;
                        <NButton
                            className={'float-end mx-1 btn-success text-white'}
                            size={'sm'}
                            buttonIconLeft={'fa fa-bars'}
                            onClick={() => navigate('/amenities')}
                            loading={amenityStore.loading}
                        />
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}>
                                <NTextInput
                                    name={'name'}
                                    value={name}
                                    onUpdate={setName}
                                    label={'Name'}
                                    placeholder={'Enter amenity name....'}
                                    required
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <NButton
                            className={'text-white px-4 mx-1 fw-bold float-end'}
                            color={'success'}
                            buttonText={form_type === 'create' ? 'Create' : 'Update'}
                            loading={amenityStore.loading}
                            disabled={disabled}
                            onClick={handleButton}
                        />
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default AmenityForm