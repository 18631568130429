import { Col, Container, Row } from '@nanobits/react-ui'
import { forwardRef, useEffect } from 'react'

import Lottie from 'react-lottie-player'
import WaitingData from '../../../assets/lottie/WaitingData.json'
import { useNavigate } from 'react-router-dom'

interface WaitingLoaderProps {
    link?: string
    timer?: number
}

const WaitingLoader = forwardRef<HTMLDivElement, WaitingLoaderProps>((
  {
    link,
    timer
  },
  ref
)=> {

  const navigation = useNavigate()

  useEffect(()=>{
    const timeOut = setTimeout(()=>{
      if(link) navigation(link)
    },(timer || 500))
    return () => clearTimeout(timeOut)
  },[link, navigation, timer])

  return (
    <Container ref={ref} fluid className={'bg-primary'}>
      <Row className={'nano-app-h-100'}>
        <Col md={6} className={'m-auto'}>
          <Lottie
            loop
            animationData={WaitingData}
            play
            rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}}
          />
        </Col>
      </Row>
    </Container>
  )
})

export default WaitingLoader