import { Alert, Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { NTextInput } from "../../monorep"
import { NButton } from "@nanobits/react-components"
import { useAuthStore } from "../../stores"
import { useEffect, useState } from "react"
import { PasswordChangeAPIRequest } from "../../models/auth/model"

const PasswordChange = () => {

    const authStore = useAuthStore()

    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined)

    const handleSubmit = () => {
        const postData: PasswordChangeAPIRequest = {
            password: password,
            current_password: currentPassword
        }
        if (password !== confirmPassword) {
            setErrorMessage('Password mismatch')
            setPassword('')
            setConfirmPassword('')
            return
        }

        authStore.postPasswordChange(postData, () => {
            setPassword('')
            setConfirmPassword('')
            setCurrentPassword('')
        })
    }

    useEffect(() => {
        if (errorMessage) {
            const timeout = setTimeout(() => setErrorMessage(undefined), 10000)
            return () => clearTimeout(timeout)
        }
    }, [errorMessage])

    return (
        <Card>
            <CardHeader className={'bg-primary text-white'}>{'Change Password'}</CardHeader>
            <CardBody>
                { errorMessage && <Alert color={'danger'}>{errorMessage}</Alert> }
                <Row>
                    <Col className={'mb-3'} md={12} sm={12}>
                       <NTextInput
                         name={'current-password'}
                         label={'Current Password'}
                         type={'password'}
                         placeholder={'Enter current password'}
                         value={currentPassword}
                         onUpdate={setCurrentPassword}
                         required
                       />
                    </Col>
                    <Col className={'mb-3'} md={12} sm={12}>
                       <NTextInput
                         name={'new-password'}
                         label={'Password'}
                         type={'password'}
                         placeholder={'Enter password'}
                         value={password}
                         onUpdate={setPassword}
                         required
                       />
                    </Col>
                    <Col className={'mb-3'} md={12} sm={12}>
                       <NTextInput
                         name={'confirm-new-password'}
                         label={'Confirm Password'}
                         type={'password'}
                         placeholder={'Enter new password again'}
                         value={confirmPassword}
                         onUpdate={setConfirmPassword}
                         required
                       />
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <NButton
                  className={'text-white fw-bold float-end'}
                  buttonText={'Change'}
                  loading={authStore.loading}
                  onClick={handleSubmit}
                />
            </CardFooter>
        </Card>
    )
}

export default PasswordChange