import { create } from 'zustand'
import { UploadAPIResponse } from '../models/upload/model'
import { uploadRepository } from '../models/upload/repository'
import { AxiosProgressEvent } from 'axios'

interface UploadStateInterface {
  loading: boolean
  error: null|string
  success: null|string
  progress: number
  files: UploadAPIResponse[]
}

interface UploadActionInterface {
  setLoading: (loading: boolean) => void
  setError: (value: string|null) => void
  postUpload: (data: FormData, callback?: () => void) =>  void
  resetStore: () => void
  resetAlert: () => void
}

const initial = {
  loading: false,
  error: null,
  success: null,
  progress: 0,
  files: [] as UploadAPIResponse[],
}

const useUploadStore = create<UploadStateInterface & UploadActionInterface>((set) => ({
  ...initial,
  
  setLoading: (loading: boolean) => set(() => ({ loading: loading })),
  setError: (value: string | null) => set(() => ({ error: value })),
  resetStore: () => set(() => (initial)),
  resetAlert: () => set(() => ({ success: null, error: null })),

  /**
   * Post file
   * @param {'auth'|'academic'|'administrative'|'enrollment'|'ledger'} service 'auth'|'academic'|'administrative'|'enrollment'|'ledger'
   * @param {FormData} data FormData
   * @param {() => void} callback () => void
   */
  postUpload: async (data: FormData, callback?: () => void) => {
    set({ loading: true, error: null, progress: 0 })
    const response = await uploadRepository.uploadFile(data, (event: AxiosProgressEvent) =>  set({ progress: calculateProgress(event) }))
    if(response.data){
      set({ files: response.data })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  }
}))

const calculateProgress = (event: AxiosProgressEvent) => {
  if('loaded' in event && 'total' in event && event.total){
    return Math.round((100 * event.loaded) / event.total)
  }
  return 0
}

export default useUploadStore
