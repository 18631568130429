import { Suspense } from 'react'
import { WaitingLoader } from '../layout/theme/fallback'
import { GlobalBody } from '../layout/theme/global'
import { Profile } from '../features'

const ProfilePage = () => {
    return (
        <Suspense fallback={<WaitingLoader />}>
            <GlobalBody>
                <Profile />
            </GlobalBody>
        </Suspense>
    )
}

export default ProfilePage