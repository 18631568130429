import { Suspense } from 'react'
import { WaitingLoader } from '../layout/theme/fallback'
import { AuthBody } from '../layout/theme/global'
import { AccountSetup } from '../features'

const AccountSetupPage = () => {
    return (
        <Suspense fallback={<WaitingLoader/>}>
            <AuthBody>
                <AccountSetup />
            </AuthBody>
        </Suspense>
    )
}

export default AccountSetupPage