import { AxiosError } from 'axios'
import { APIService } from '@bigbroindia/barebone'
import { CountryDropdownResponse, CountryRepository } from './interface'
import { countryDropdownMap } from './mapper'
import { APIError, CountryDropdownAPIRequestFilter, CountryDropdownAPIResponse } from './model'
import { Repository } from '../../@types'


async function getCountryDropdown(filters: CountryDropdownAPIRequestFilter): Promise<CountryDropdownResponse> {
  try {
    const response: CountryDropdownAPIResponse = await APIService.get('/a/countries/dropdown', filters)
    return {
      data: countryDropdownMap(response),
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('Country Dropdown api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

export const countryRepository: Repository<CountryRepository> = {
  getCountryDropdown
}