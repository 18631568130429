import { Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row } from "@nanobits/react-ui"
import { NTextInput } from "../../monorep"
import { NButton } from "@nanobits/react-components"
import { useState } from "react"
import { useUserStore } from "../../stores"

interface OwnerInformationModalInterface {
    showModal: boolean
    setShowModal: (value: boolean) => void
    setOwnerId: (value: string) => void
}

const OwnerInformationModal = ({
    showModal,
    setShowModal,
    setOwnerId
}: OwnerInformationModalInterface) => {

    const userStore = useUserStore()

    const [id, setId] = useState<string>('')

    const handleSubmit = () => {
        userStore.findUser(id, () => {
            setId('')
            setOwnerId(userStore.owner.id)
            setShowModal(false)
        })
    }

    const disable = !id

    return (
        <Modal
          visible={showModal}
          onClose={() => setShowModal(false)}
          alignment={'center'}
          size={'sm'}
        >
            <ModalHeader className={'bg-primary text-white'} >
                <ModalTitle>{'Find Owner'}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12} className={'mb-3'}>
                        <NTextInput
                          name={'user_information'}
                          label={'Email / Mobile'}
                          placeholder={'Enter email or mobile number'}
                          required
                          value={id}
                          onUpdate={setId}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <NButton
                  buttonText={'Search'}
                  onClick={handleSubmit}
                  loading={userStore.loading}
                  disabled={disable}
                />
            </ModalFooter>
        </Modal>
    )
}

export default OwnerInformationModal