import { CommonAPIRequestFilter, CommonDropdownAPIRequestFilter, CommonStoreActionInterface, CommonStoreStateWithDropdownInterface, MetaAPIResponse, SelectInputItemInterface } from '../@types'
import { initialStoreState } from '../common/values'
import { CategoryAPIMapResponseInterface, CategoryAPIPayloadInterface, CategoryAPIResponseInterface } from '../models/category/model'
import { create } from 'zustand'
import { categoryRepository } from '../models/category/repository'

interface CategoryStateInterface extends CommonStoreStateWithDropdownInterface {
    categories: CategoryAPIMapResponseInterface[]
    category: CategoryAPIResponseInterface
    meta: MetaAPIResponse
}

interface CategoryActionInterface extends CommonStoreActionInterface {
    getCategories: (filters: CommonAPIRequestFilter, callback?: () => void) => void
    getCategory: (id: number, callback?: () => void) => void
    getCategoryDropdown: (filters: CommonDropdownAPIRequestFilter) => void
    postCategory: (payload: CategoryAPIPayloadInterface, callback?: () => void) => void
    updateCategory: (id: number, payload: CategoryAPIPayloadInterface, callback?: () => void) => void
}

const initial: CategoryStateInterface = {
    ...initialStoreState,
    categories: [] as CategoryAPIMapResponseInterface[],
    category: {} as CategoryAPIResponseInterface,
    dropdown: [] as SelectInputItemInterface[],
    meta: {} as MetaAPIResponse,
}

const useCategoryStore = create<CategoryStateInterface & CategoryActionInterface>((set) => ({
    ...initial,
    setLoading: (loading: boolean) => set(() => ({ loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    resetStore: () => set(() => ({ ...initial })),
    resetAlert: () => set(() => ({ success: null, error: null })),

    getCategories: async (filters: CommonAPIRequestFilter, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await categoryRepository.getCategories(filters)
        if (response.data) {
            set({ categories: response.data.list, meta: response.data.meta })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getCategory: async (id: number, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await categoryRepository.getCategory(id)
        if (response.data) {
            set({ category: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getCategoryDropdown: async (filters: CommonDropdownAPIRequestFilter) => {
        set({ loading: true, error: null })
        const response = await categoryRepository.dropdown(filters)
        if (response.data) {
            set({ dropdown: response.data.items })
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postCategory: async (payload: CategoryAPIPayloadInterface, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await categoryRepository.createCategory(payload)
        if (response.data) {
            set({ category: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    updateCategory: async (id: number, payload: CategoryAPIPayloadInterface, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await categoryRepository.updateCategory(id, payload)
        if (response.data) {
            set({ category: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    }
}))

export default useCategoryStore
