import { AxiosError, AxiosProgressEvent } from 'axios'
import AxiosService from '../../services'
import { APIError, UploadAPIResponse } from './model'
import { Repository } from '../../@types'
import { UploadResponse, UploadRepository } from './interface'

/**
 * 
 * @param {string} service string
 * @param {FormData} payload FormData
 * @param {event: AxiosProgressEvent) => void} callback event: AxiosProgressEvent) => void
 * @returns {UploadResponse} response
 */
async function uploadFile (payload: FormData, callback: (event: AxiosProgressEvent) => void): Promise<UploadResponse> {
  try {
    const response: UploadAPIResponse[] = await AxiosService.uploadWithProgress('/a/upload', payload, callback)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError)?.response?.data as APIError
    console.error('Upload api error response', JSON.stringify(error))
    console.log(err)
    return {
      data: null,
      error: error.error
    }
  }
}

export const uploadRepository: Repository<UploadRepository> = {
  uploadFile
}