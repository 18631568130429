import { Suspense } from 'react'
import { WaitingLoader } from '../layout/theme/fallback'
import { GlobalBody } from '../layout/theme/global'
import { AmenityForm, AmenityList, AmenityAssignForm } from '../features'

interface AmenityPageInterface {
    pageType: 'list' | 'form' | 'assign'
    formType?: 'create' | 'update'
}

const AmenityPage = ({
    pageType,
    formType
}: AmenityPageInterface) => {
    return (
        <Suspense fallback={<WaitingLoader/>}>
            <GlobalBody>
                { pageType === 'list' && <AmenityList/> }
                { pageType === 'form' && <AmenityForm form_type={formType} /> }
                { pageType ==='assign' && <AmenityAssignForm/> }
            </GlobalBody>
        </Suspense>
    )
}

export default AmenityPage