import { AxiosError } from 'axios'
import { APIError, CommonAPIRequestFilter, CommonDropdownAPIRequestFilter } from '../../@types'
import { APIService } from '@bigbroindia/barebone'
import { AmenityAPIPayloadInterface, AmenityDropdownAPIResponseInterface, AmenityListAPIResponseInterface, PropertyAmenityAPIPayloadInterface, PropertyAmenityAPIRequestInterface } from './model'
import { AmenityDropdownMapper, AmenityListMapper } from './mapper'
import { AmenityRepository } from './interface'

async function getAmenities(filters: CommonAPIRequestFilter){
    try {
        const response: AmenityListAPIResponseInterface = await APIService.get('/a/amenities', filters)
        return {
            data: AmenityListMapper(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Amenity list api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getAmenityDropdown(filters: CommonDropdownAPIRequestFilter){
    try {
        const response: AmenityDropdownAPIResponseInterface = await APIService.get('/a/amenities/dropdown', filters)
        return {
            data: AmenityDropdownMapper(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Amenity dropdown api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getAmenity(id: number){
    try {
        const response = await APIService.get(`/a/amenities/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Amenity api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getPropertyAmenity(filters: PropertyAmenityAPIRequestInterface){
    try {
        const response = await APIService.get('/a/property-amenities/property', filters)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Property Amenity list api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function createAmenity(payload: AmenityAPIPayloadInterface){
    try {
        const response = await APIService.post('/a/amenities', payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Amenity create api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function updateAmenity(id: number, payload: AmenityAPIPayloadInterface){
    try {
        const response = await APIService.put(`/a/amenities/${id}`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Amenity update api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function createPropertyAmenity(payload: PropertyAmenityAPIPayloadInterface){
    try {
        const response = await APIService.post('/a/property-amenities', payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Property Amenity add api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function deletePropertyAmenity(id: number){
    try {
        const response = await APIService.destroy(`/a/property-amenities/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Property Amenity add api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const amenityRepository: AmenityRepository = {
    getAmenities,
    getAmenity,
    getAmenityDropdown,
    getPropertyAmenity,
    createAmenity,
    updateAmenity,
    createPropertyAmenity,
    deletePropertyAmenity
}