import { titleCase } from '@bigbroindia/helper'
import { FlatAPIMap, FlatAPIResponse, FlatListAPIMap, FlatListAPIResponse } from './model'

export const flatListMap = ( data: FlatListAPIResponse ): FlatListAPIMap => {

    const { list, meta } = data

    const mappedList = list.map((item: FlatAPIResponse) => {
        return {
            id: item.id,
            name: item.projectName,
            bhk: `${item.bhk} BHK`,
            furnishing: titleCase(item.furnishing),
            views: item.views,
            wishlists: item.wishlists,
            status: titleCase(item.propertyStatus),
            city: item.city.name,
            for: titleCase(item.type),
            user: item.user.name
        } as FlatAPIMap
    })

    return {
        list: mappedList,
        meta
    }
}