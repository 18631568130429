import { formatDateTime, titleCase } from '@bigbroindia/helper'
import { RoleAPIMap, RoleAPIResponse, RoleDropdownAPIResponse, RoleDropdownMapResponse, RoleListAPIMap, RoleListAPIResponse, RolePermissionAPIResponse } from './model'
import { SelectInputItemInterface } from '../../@types'

export const roleListMap = (data: RoleListAPIResponse): RoleListAPIMap => {

    const { list, meta } = data

    const mappedList = list.map((item: RoleAPIResponse) => {
        return {
            id: item.id,
            name: item.name,
            userType: titleCase(item.userType),
            roleType: titleCase(item.roleType),
            createdAt: formatDateTime(item.createdAt),
            updatedAt: formatDateTime(item.updatedAt)
        } as RoleAPIMap
    })

    return {
        list: mappedList,
        meta
    }
}

export const rolePermissionMap = (data: RolePermissionAPIResponse[]): number[] => {

    const permissionsId = data.map((permission) => permission.permissionId)

    return permissionsId
}

export const roleDropdownMap = (data: RoleDropdownAPIResponse): RoleDropdownMapResponse => {

    const { items } = data

    const mappedItems = items.map((item) => {
        return {
            label: item.name,
            value: String(item.id)
        } as SelectInputItemInterface
    })

    return {
        items: mappedItems
    }
}