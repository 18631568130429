import { PermissionAPIResponse } from '../models/permissions/model'

interface FormattedPermissionInterface {
    name: string
    permissions: PermissionAPIResponse[]
}

const getFormattedPermission = ( permissions: PermissionAPIResponse[] ): FormattedPermissionInterface [] => {

    const groupedPermissions: FormattedPermissionInterface[] = []

    permissions.forEach( permission => {

        const [resource, action] = permission.name.split('.')

        let group = groupedPermissions.find(gp => gp.name === resource)

        if (!group) {
            group = {
                name: resource,
                permissions: []
            }
            groupedPermissions.push(group)
        }

        group.permissions.push({
            ...permission,
            name: action
        })
    } )

    return groupedPermissions
}

export default getFormattedPermission