import { create } from 'zustand'
import { CityAPIMAPResponse, CityAPIRequest, CityAPIRequestFilter, CityAPIResponse, CityDropdownAPIRequestFilter, CityForm, CityListAPIRequestFilter, MetaAPIResponse } from '../models/city/model'
import { SelectInputItemInterface } from '../@types'
import { cityRepository } from '../models/city/repository'
import { cityFormMap } from '../models/city/mapper'

interface CityStateInterface {
  loading: boolean
  error: null|string
  success: string|null
  city: CityAPIResponse
  cities: CityAPIMAPResponse[]
  dropdown: SelectInputItemInterface[]
  meta: MetaAPIResponse
  form: CityForm
}

interface CityActionInterface {
  setLoading: (loading: boolean) => void
  setError: (value: string | null) => void
  resetStore: () => void
  resetAlert: () => void
  getCities: (filters: CityListAPIRequestFilter, callback?: () => void) => void
  getCity: (id: number,filters: CityAPIRequestFilter, callback?: () => void) => void
  getCitiesDropdown: (filters: CityDropdownAPIRequestFilter, callback?: () => void) => void
  postCity: (payload: CityAPIRequest, callback?: () => void) => void
  putCity: (id: number, payload: CityAPIRequest, callback?: () => void) => void
  patchCity: (id: number, callback?: () => void) => void
}

const initial: CityStateInterface = {
  loading: false as boolean,
  error: null,
  success: null,
  meta: {} as MetaAPIResponse,
  form: {} as CityForm,
  cities: [] as CityAPIMAPResponse[],
  city: {} as CityAPIResponse,
  dropdown: [] as SelectInputItemInterface[]
}

const useCityStore = create<CityStateInterface & CityActionInterface>((set) => ({
  ...initial,
  setLoading: (loading: boolean) => set(() => ({ loading: loading })),
  setError: (value: string | null) => set(() => ({ error: value })),
  resetStore: () => set(() => (initial)),
  resetAlert: () => set(() => ({ success: null, error: null })),
  
  getCities: async (filters: CityListAPIRequestFilter, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await cityRepository.getCities(filters)
    if (response.data) {
      set({ cities: response.data.list, meta: response.data.meta })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  getCity: async (id: number, filters: CityAPIRequestFilter, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await cityRepository.getCity(id, filters)
    if (response.data) {
      set({ city: response.data, form: cityFormMap(response.data) })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  getCitiesDropdown: async (filters: CityDropdownAPIRequestFilter, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await cityRepository.getCityDropdown(filters)
    if (response.data) {
      set({ dropdown: response.data.items })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  postCity: async ( payload: CityAPIRequest, callback?: () => void ) => {
    set({ loading: true, error: null })
    const response = await cityRepository.postCity(payload)
    if (response.data) {
      set({ success: 'cityCreatedSuccess' })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  putCity: async (id: number, payload: CityAPIRequest, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await cityRepository.putCity(id, payload)
    if (response.data) {
      set({ success: 'cityUpdatedSuccess' })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  patchCity: async (id: number, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await cityRepository.patchCity(id)
    if (response.data) {
      set({ success: response.data.message })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  }
}))

export default useCityStore