import { AxiosError } from 'axios'
import { APIService } from '@bigbroindia/barebone'
import { StateDropdownResponse, StateListResponse, StateRepository, StateResponse, StateStausResponse } from './interface'
import { stateDropdownMap, stateListMap } from './mapper'
import { APIError, StateAPIRequest, StateAPIRequestFilter, StateAPIResponse, StateDropdownAPIRequestFilter, StateDropdownAPIResponse, StateListAPIRequestFilter, StateListAPIResponse, StateStatusAPIResponse } from './model'
import { Repository } from '../../@types'

async function getStates (filters: StateListAPIRequestFilter): Promise<StateListResponse> {
  try {
    const response: StateListAPIResponse = await APIService.get('/a/states', filters)
    return {
      data: stateListMap(response),
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('State list api response error', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function getState(id: number, filters: StateAPIRequestFilter): Promise<StateResponse> {
  try {
    const response: StateAPIResponse = await APIService.get(`/a/states/${id}`, filters)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('State api response error', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function getStateDropdown(filters: StateDropdownAPIRequestFilter): Promise<StateDropdownResponse> {
  try {
    const response: StateDropdownAPIResponse = await APIService.get('/a/states/dropdown', filters)
    return {
      data: stateDropdownMap(response),
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('State Dropdown api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function postState(payload: StateAPIRequest): Promise<StateResponse>{
  try {
    const response: StateAPIResponse = await APIService.post('/a/states', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('State post api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function putState(id: number,payload: StateAPIRequest): Promise<StateResponse>{
  try {
    const response: StateAPIResponse = await APIService.put(`/a/states/${id}`, payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('State update api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function patchState(id: number): Promise<StateStausResponse>{
  try {
    const response: StateStatusAPIResponse = await APIService.patch(`/a/states/${id}/status`)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('State update api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

export const stateRepository: Repository<StateRepository> = {
  getStates,
  getState,
  getStateDropdown,
  postState,
  putState,
  patchState
}