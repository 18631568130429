import { formatDateTime } from '@bigbroindia/helper'
import { CategoryListAPIMapResponseInterface, CategoryListAPIResponseInterface, CategoryAPIMapResponseInterface, CategoryDropdownAPIResponseInterface, CategoryDropdownAPIMapInterface } from './model'
import { SelectInputItemInterface } from '../../@types'

export const CategoryListMapper = (data: CategoryListAPIResponseInterface): CategoryListAPIMapResponseInterface => {
    const { list, meta } = data
    const items = list.map((item) => {
        return {
            id: item.id,
            name: item.name,
            status: item.status ? 'Active' : 'Not Active',
            updatedOn: formatDateTime(item.updatedAt)
        } as CategoryAPIMapResponseInterface
    })

    return {
        list: items,
        meta
    }
}

export const categoryDropdownMapper = (data: CategoryDropdownAPIResponseInterface): CategoryDropdownAPIMapInterface => {
    const { items } = data
    
    const mappedItems = items.map((item) => {
        return {
            value: String(item.id),
            label: item.name
        } as SelectInputItemInterface
    })

    return {
        items: mappedItems
    }
}