import { formatDateTime } from '@bigbroindia/helper'
import { AmenityAPIMapResponseInterface, AmenityDropdownAPIResponseInterface, AmenityDropdownMapInterface, AmenityListAPIMapResponseInterface, AmenityListAPIResponseInterface } from './model'
import { SelectInputItemInterface } from '../../@types'

export const AmenityListMapper = (data: AmenityListAPIResponseInterface): AmenityListAPIMapResponseInterface => {
    const { list, meta } = data
    
    const items = list.map((item) => {
        return {
            id: item.id,
            name: item.name,
            category: item.category.name,
            status: item.status ? 'Active' : 'Not Active',
            updatedOn: formatDateTime(item.updatedAt)
        } as AmenityAPIMapResponseInterface
    })

    return {
        list: items,
        meta
    }
}

export const AmenityDropdownMapper = (data: AmenityDropdownAPIResponseInterface): AmenityDropdownMapInterface => {
    const { items } = data

    const mappedItems = items.map((item) => {
        return {
            label: item.name,
            value: String(item.id)
        } as SelectInputItemInterface
    })

    return {
        items: mappedItems
    }
}