import { SelectInputItemInterface } from '../../@types'
import { CountryAPIDropdownAPIMAPResponse, CountryDropdownAPIResponse } from './model'

const countryDropdownMap = (data: CountryDropdownAPIResponse): CountryAPIDropdownAPIMAPResponse => {
  const {items} = data
  const mappedItems = items.map((item) => {
    return {
      value: String(item.id),
      label: item.name
    } as SelectInputItemInterface
  })
  return {
    items: mappedItems
  }
}

export {
  countryDropdownMap
}