import { AxiosError } from 'axios'
import { CommonAPIRequestFilter, APIError, CommonDropdownAPIRequestFilter } from '../../@types'
import { CategoryAPIPayloadInterface, CategoryDropdownAPIResponseInterface, CategoryListAPIResponseInterface } from './model'
import { APIService } from '@bigbroindia/barebone'
import { categoryDropdownMapper, CategoryListMapper } from './mapper'
import { CategoryRepository } from './interface'

async function getCategories(filters: CommonAPIRequestFilter) {
    try {
        const response: CategoryListAPIResponseInterface = await APIService.get('/a/categories', filters)
        return {
            data: CategoryListMapper(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Category list api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getCategory(id: number) {
    try {
        const response = await APIService.get(`/a/categories/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Category api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function createCategory(payload: CategoryAPIPayloadInterface) {
    try {
        const response = await APIService.post('/a/categories', payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Category create api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function updateCategory(id: number, payload: CategoryAPIPayloadInterface) {
    try {
        const response = await APIService.put(`/a/categories/${id}`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Category update api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function dropdown(filters: CommonDropdownAPIRequestFilter) {
    try {
        const response: CategoryDropdownAPIResponseInterface = await APIService.get('/a/categories/dropdown', filters)
        return {
            data: categoryDropdownMapper(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError).response?.data as APIError
        console.error('Category list api error response', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const categoryRepository: CategoryRepository = {
    getCategories,
    getCategory,
    createCategory,
    updateCategory,
    dropdown
}