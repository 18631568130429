import { create } from 'zustand'
import { RoleAPIMap, RoleAPIResponse, RoleDropdownAPIRequest, RolePermissionsPostAPIPayload, RolePostAPIPayload } from '../models/roles/model'
import { CommonAPIRequestFilter, MetaAPIResponse, SelectInputItemInterface } from '../@types'
import { rolesRepository } from '../models/roles/repository'

interface RoleStateInterface {
    loading: boolean
    error: string | null
    success: string | null
    role: RoleAPIResponse
    roles: RoleAPIMap[]
    currentPage: number
    meta: MetaAPIResponse
    rolePermissions: number[]
    dropdown: SelectInputItemInterface[]
}

interface RoleActionInterface {
    setLoading: (loading: boolean) => void
    setError: (value: string | null) => void
    resetStore: () => void
    resetAlert: () => void
    setCurrentPage: (page: number) => void
    getRoles: (filters?: CommonAPIRequestFilter, callback?: () => void) => void
    getRole: (id: number, callback?: () => void) => void
    getRolePermissions: (id: number, callback?: () => void) => void
    postRole: (payload: RolePostAPIPayload, callback?: () => void) => void
    updateRole: (id: number, payload: RolePostAPIPayload, callback?: () => void) => void
    postRolePermissions: (id: number, payload: RolePermissionsPostAPIPayload, callback?: () => void) => void
    getRoleDropdown: (filters?: RoleDropdownAPIRequest, callback?: () => void) => void
}

const initial: RoleStateInterface = {
    loading: false,
    error: null,
    success: null,
    currentPage: 1,
    role: {} as RoleAPIResponse,
    roles: [] as RoleAPIMap[],
    meta: {} as MetaAPIResponse,
    rolePermissions: [] as number[],
    dropdown: [] as SelectInputItemInterface[]
}

const useRoleStore = create<RoleStateInterface & RoleActionInterface>((set) => ({
    ...initial,

    setLoading: (loading: boolean) => set(() => ({ loading: loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    resetStore: () => set(() => (initial)),
    resetAlert: () => set(() => ({ success: null, error: null })),
    setCurrentPage: (page: number) => set(() => ({ currentPage: page })),

    getRoles: async (filters?: CommonAPIRequestFilter, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await rolesRepository.getRoles(filters)
        if (response.data) {
            set({ roles: response.data.list, meta: response.data.meta })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getRole: async (id: number, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await rolesRepository.getRole(id)
        if (response.data) {
            set({ role: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getRolePermissions: async (id: number, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await rolesRepository.getRolePermissions(id)
        if (response.data) {
            set({ rolePermissions: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postRole: async (payload: RolePostAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await rolesRepository.postRole(payload)
        if (response.data) {
            set({ role: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    updateRole: async (id: number, payload: RolePostAPIPayload ,callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await rolesRepository.putRole(id, payload)
        if (response.data) {
            set({ role: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postRolePermissions: async (id: number, payload: RolePermissionsPostAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await rolesRepository.postRolePermissions(id, payload)
        if (response.data) {
            set({ rolePermissions: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getRoleDropdown: async (filters?: RoleDropdownAPIRequest, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await rolesRepository.getRoleDropdown(filters)
        if (response.data) {
            set({ dropdown: response.data.items })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    }
}))

export default useRoleStore