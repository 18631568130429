import { SelectInputItemInterface } from '../../@types'
import { formatDateTime, getStatus } from '@bigbroindia/helper'
import { CityAPIDropdownAPIMAPResponse, CityAPIMAPResponse, CityAPIResponse, CityDropdownAPIResponse, CityForm, CityListAPIMAPResponse, CityListAPIResponse } from './model'

const cityListMap = (data: CityListAPIResponse): CityListAPIMAPResponse => {
  const { list, meta } = data
  const mappedList = list.map((item: CityAPIResponse, index: number) => {
    return {
      _id: item.id,
      serial: (index+1) + ((meta.page-1) * meta.per_page),
      name: item.name,
      state: item.state.name,
      country: item.country.name,
      status: getStatus(item.status),
      updateOn: formatDateTime(new Date(item.updatedAt))
    } as CityAPIMAPResponse
  })
  return {
    list: mappedList,
    meta
  }
}

const cityDropdownMap = (data: CityDropdownAPIResponse): CityAPIDropdownAPIMAPResponse => {
  const {items} = data
  const mappedItems = items.map((item) => {
    return {
      value: String(item.id),
      label: item.name
    } as SelectInputItemInterface
  })
  return {
    items: mappedItems
  }
}

const cityFormMap = (data: CityAPIResponse): CityForm => {
  return {
    country_id: String(data.countryId) ?? null,
    state_id: String(data.stateId) ?? null,
    name: data.name ?? null
  }
}

export {
  cityListMap,
  cityDropdownMap,
  cityFormMap
}