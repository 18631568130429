import { CameraModalTypeProp, DeviceInfoType } from './type'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@nanobits/react-ui'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { NButton } from '@nanobits/react-components'
import Webcam from 'react-webcam'

export const Camera = ({
    showCameraModel,
    setShowCameraModel,
    setCameraThumb,
    setFileToUpload
}:CameraModalTypeProp) => {
    const webcamRef = useRef<any>(null)
    const [devices, setDevices] = useState<DeviceInfoType[]>([])
    const [switchCamera, setSwitchCamera] = useState<object>({facingMode:'user'})
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot()
        setCameraThumb(imageSrc)
        setFileToUpload(imageSrc)
        setShowCameraModel(false)
    },[setCameraThumb, setShowCameraModel, setFileToUpload])

    const switchCameraHandle = (deviceId: string) => {
        setSwitchCamera({deviceId:deviceId})
    }

    const handleDevices = useCallback(
        (mediaDevices: any) =>
          setDevices(mediaDevices.filter(({ kind }:{kind: any}) => kind === 'videoinput')),
        [setDevices]
    )

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices)
    },[handleDevices])

    return (
        <Modal
            visible={showCameraModel}
            onClose={()=>setShowCameraModel(false)}
        >
            <ModalHeader closeButton>
                <ModalTitle>{'Camera'}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Webcam
                    ref={webcamRef}
                    width={465}
                    screenshotFormat={'image/jpeg'}
                    videoConstraints={switchCamera}
                    audio={false}
                />
            </ModalBody>
            <ModalFooter>
                {(devices?.length > 0) && devices.map((device: DeviceInfoType)=>{
                        return (<NButton key={device.deviceId} className={'btn-block'} buttonIconLeft={'fa-arrow-right-arrow-left'} buttonText={device?.label} onClick={()=>switchCameraHandle(device.deviceId)}/>)
                    }
                )}
                <NButton  className={'btn-block'} buttonIconLeft={'fa-camera'} buttonText={'Take'} onClick={()=>capture()}/>
            </ModalFooter>
        </Modal>
    )
}
