import { Col, Container, Row } from "@nanobits/react-ui"
import { forwardRef, useEffect } from "react"

import Lottie from "react-lottie-player"
import bug from '../../../assets/lottie/bug.json'
import { useNavigate } from "react-router-dom"

interface BugLoaderProps {
  location?: string,
  timer?: number,
  message?: string
}

const BugLoader = forwardRef<HTMLDivElement, BugLoaderProps>((
  { location, timer },
  ref
) => {

  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  useEffect(() => {
    setTimeout(() => {
      if (location)
        navigate(location)
    }, (timer || 500))
  }, [location, timer, navigate])

  return (
    <Container ref={ref} fluid onClick={handleClick}>
      <Row className={'nano-app-h-100'}>
        <Col md={6} className={'m-auto'}>
          <Lottie
            loop
            animationData={bug}
            play
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </Col>
      </Row>
    </Container>
  )
}
)
export default BugLoader