import { AxiosError } from 'axios'
import { APIError, CommonAPIRequestFilter } from '../../@types'
import { RolePermissionsResponse, RoleRepository, RoleResponse, RolesListResponse } from './interface';
import { APIService } from '@bigbroindia/barebone';
import { roleDropdownMap, roleListMap } from './mapper';
import { RoleDropdownAPIRequest, RolePermissionsPostAPIPayload, RolePostAPIPayload } from './model';

async function getRoles (filters: CommonAPIRequestFilter): Promise<RolesListResponse> {
    try {
        const response = await APIService.get('/a/roles', filters)
        return {
            data: roleListMap(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Roles list api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getRole (id: number): Promise<RoleResponse> {
    try {
        const response = await APIService.get(`/a/roles/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Role api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getRolePermissions (id: number): Promise<RolePermissionsResponse> {
    try {
        const response = await APIService.get(`/a/roles/${id}/permissions`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Roles permission api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function postRole (payload: RolePostAPIPayload): Promise<RoleResponse> {
    try {
        const response = await APIService.post('/a/roles', payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Roles post api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function putRole (id: number, payload: RolePostAPIPayload): Promise<RoleResponse> {
    try {
        const response = await APIService.put(`/a/roles/${id}`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Roles post api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function postRolePermissions (id: number, payload: RolePermissionsPostAPIPayload) {
    try {
        const response =  await APIService.post(`/a/roles/${id}/permissions`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Role permissions post api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getRoleDropdown (filters: RoleDropdownAPIRequest) {
    try {
        const response = await APIService.get('/a/roles/dropdown', filters)
        return {
            data: roleDropdownMap(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Role dropdown api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const rolesRepository: RoleRepository = {
    getRoles,
    getRole,
    getRolePermissions,
    postRole,
    putRole,
    postRolePermissions,
    getRoleDropdown
}