import { Card, CardBody, CardFooter, CardHeader, Div, FormCheck, Table, TableBody, TableDataCell, TableHead, TableHeaderCell, TableRow } from '@nanobits/react-ui'
import { UserLogsInterface } from '../../models/user/model'
import { formatDateTime } from '@bigbroindia/helper'
import { NButton } from '@nanobits/react-components'
import { useAuthStore } from '../../stores'
import { useState } from 'react'
import { LogoutAPIRequest } from '../../models/auth/model'

const LoginLogsCard = () => {

    const authStore = useAuthStore()

    const [id, setId] = useState<string[]>([] as string[])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            id.push(event.currentTarget.value)
            const newData = id.map(ids => ids)
            setId(newData)
        } else {
            const newData = id.filter(id => id !== event.currentTarget.value)
            setId(newData)
        }
    }

    const handleAllSessionsLogout = () => {
        const postData: LogoutAPIRequest = {
            type: 'purge',
            id: authStore.profile.userLogs.map((id) => id.id)
        }
        authStore.postSessionLogout(postData, () => {
            localStorage.clear()
        })
    }

    const handleSelectedSessionLogout = () => {
        const postData: LogoutAPIRequest = {
            type: 'multiple',
            id: id
        }

        authStore.postSessionLogout(postData, () => {
            authStore.getProfile(() => {
                setId([] as string[])
            })
        })
    }

    return (
        <Card>
            <CardHeader className={'bg-primary text-white'}>{`Previous Active Sessions (${authStore.profile?.userLogs?.length})`}</CardHeader>
            <CardBody>
                <Div className={'table-responsive'}>
                    <Table className={'table table-striped table-hover'}>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>{''}</TableHeaderCell>
                                <TableHeaderCell>{'#'}</TableHeaderCell>
                                <TableHeaderCell>{'IP'}</TableHeaderCell>
                                <TableHeaderCell>{'OS'}</TableHeaderCell>
                                <TableHeaderCell>{'Login On'}</TableHeaderCell>
                                <TableHeaderCell>{'Last Login On'}</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {authStore?.profile?.userLogs?.map((log: UserLogsInterface, index: number) => {
                                const isCurrent = authStore.profile.logId === log.id
                                return (
                                    <TableRow key={`user-log-${log.id}`}>
                                        <TableDataCell>
                                            {isCurrent ? 'Current Session' : <FormCheck
                                              id={`user-id-check-${log.id}`}
                                              name={log.id}
                                              checked={id.includes(log.id)}
                                              value={log.id}
                                              onChange={handleChange}
                                            />}
                                        </TableDataCell>
                                        <TableDataCell>{index + 1}</TableDataCell>
                                        <TableDataCell>{log.userAgent.ip}</TableDataCell>
                                        <TableDataCell>{log.userAgent.os}</TableDataCell>
                                        <TableDataCell> {formatDateTime(log.createdAt)} </TableDataCell>
                                        <TableDataCell> {formatDateTime(log.updatedAt)} </TableDataCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Div>
            </CardBody>
            <CardFooter>
                <NButton
                    className={'text-white px-4 fw-bold float-end'}
                    color={'danger'}
                    buttonText={id.length ? `Logout Selected Sessions` : 'Logout All Sessions'}
                    loading={authStore.loading}
                    onClick={id.length ? handleSelectedSessionLogout : handleAllSessionsLogout}
                />
            </CardFooter>
        </Card>
    )
}

export default LoginLogsCard