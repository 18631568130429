import {
  faUser, faUsers, faKey, faLock, faGauge, faShoppingCart, faFolder, faRectangleAd, faFloppyDisk,
  faPersonCircleCheck, faDrum, faBars, faSquarePlus, faTrash, faPencil, faSquareCheck, faSquareXmark,
  faMagnifyingGlass, faGhost, faBook, faBookBookmark,faUpload, faCamera, faArrowRightArrowLeft, faCrop,
  faEye, faEyeSlash, faEnvelope, faPhone, faInfo, faRefresh, faGear, faList, faMoneyBill, faX, faMars, faVenus, faCircleCheck,
  faFile, faArrowLeft
} from '@fortawesome/free-solid-svg-icons'

import {
  faGoogle, faFacebook, faApple, faWindows
} from '@fortawesome/free-brands-svg-icons'

export const icons = Object.assign({}, {
  // solid svg
  faUser, faUsers, faKey, faLock, faGauge, faShoppingCart, faFolder, faRectangleAd, faFloppyDisk, faPersonCircleCheck,
  faDrum, faBars, faSquarePlus, faTrash, faPencil, faSquareCheck, faSquareXmark, faMagnifyingGlass, faGhost, faBook,
  faBookBookmark, faUpload, faCamera, faArrowRightArrowLeft, faCrop, faEye, faEyeSlash, faEnvelope, faPhone, faInfo,
  faRefresh, faGear, faList, faMoneyBill, faX, faMars, faVenus, faCircleCheck, faFile, faArrowLeft
}, {
  // brand svg
  faGoogle, faFacebook, faApple, faWindows
})