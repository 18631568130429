import { Suspense } from 'react'
import { WaitingLoader } from '../layout/theme/fallback'
import { GlobalBody } from '../layout/theme/global'
import { CityForm, CityList, StateForm, StateList } from '../features'

interface LocationPageInterface {
    location_type: 'state' | 'city'
    page_type: 'list' | 'form'
    form_type?: 'create' | 'update'
}

const LocationPage = ({
    location_type,
    page_type,
    form_type
}: LocationPageInterface) => {
    return (
        <Suspense fallback={<WaitingLoader/>}>
            <GlobalBody>
                { (location_type === 'state' && page_type === 'list') && <StateList /> }
                { (location_type === 'state' && page_type === 'form') && <StateForm formType={form_type} /> }
                { (location_type === 'city' && page_type === 'list') && <CityList/> }
                { (location_type === 'city' && page_type === 'form') && <CityForm formType={form_type} /> }
            </GlobalBody>
        </Suspense>
    )
}

export default LocationPage