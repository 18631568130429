import { PermissionAPIResponse } from '../models/permissions/model'
import { create } from 'zustand'
import { permissionRepository } from '../models/permissions/repository'

interface PermissionStateInterface {
    loading: boolean
    error: string | null
    success: string | null
    permissionsDropdown: PermissionAPIResponse[]
}

interface PermissionActionInterface {
    setLoading: (loading: boolean) => void
    setError: (value: string | null) => void
    getPermissionDropdown: () => void
}

const initial: PermissionStateInterface = {
    loading: false as boolean,
    error: null,
    success: null,
    permissionsDropdown: [] as PermissionAPIResponse[]
}

const usePermissionStore = create<PermissionStateInterface & PermissionActionInterface>((set) => ({
    ...initial,

    setLoading: (loading: boolean) => set(() => ({ loading: loading })),
    setError: (value: string | null) => set(() => ({ error: value })),

    getPermissionDropdown: async () => {
      set({ loading: true, error: null })
      const response = await permissionRepository.getPermissionDropdown()
      if (response.data) {
        set({ permissionsDropdown: response.data.items })
      } else {
        set({ error: response.error })
      }
      set({ loading: false })
    }
}))

export default usePermissionStore