import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AuthenticLayout, PreAuthenticLayout, PreSetupLayout, UserProviderLayer } from "./Authentic"
import { AccountSetupPage, AmenityPage, AuthPage, DashboardPage, LocationPage, ProfilePage, PropertyPage, ServerErrorPage, UserManagementPage } from "../pages"
import { NotFoundLoader } from "../layout/theme/fallback"

const App = () => {
    return (
        <UserProviderLayer>
            <BrowserRouter>
                <Routes>
                    <Route element={<PreAuthenticLayout />} >
                        <Route path={'/login'} element={<AuthPage page={'login'} />} />
                    </Route>
                    <Route element={<AuthenticLayout />}>
                        <Route path={'/'} element={<DashboardPage page={'admin'} />} />
                        <Route path={'/amenities'} element={<AmenityPage pageType={'list'} />} />
                        <Route path={'/amenities/add'} element={<AmenityPage pageType={'form'} formType={'create'} />} />
                        <Route path={'/amenities/:id/update'} element={<AmenityPage pageType={'form'} formType={'update'} />} />
                        <Route path={'/amenities/:type/:id/assign'} element={<AmenityPage pageType={'assign'} />} />
                        <Route path={'/profile'} element={<ProfilePage/>} />
                        <Route path={'/location'}>
                            <Route path={'/location/states'} element={ <LocationPage location_type={'state'} page_type={'list'} /> } />
                            <Route path={'/location/states/add'} element={ <LocationPage location_type={'state'} page_type={'form'} form_type={'create'} /> } />
                            <Route path={'/location/cities'} element={ <LocationPage location_type={'city'} page_type={'list'} /> } />
                            <Route path={'/location/cities/add'} element={ <LocationPage location_type={'city'} page_type={'form'} form_type={'create'} /> } />
                        </Route>
                        <Route path={'/roles'} element={<UserManagementPage module={'role'} page_type={'list'} />} />
                        <Route path={'/users'} element={<UserManagementPage module={'user'} page_type={'list'} />} />
                        <Route path={'/property'}>
                            <Route path={'/property/flats'} element={<PropertyPage property_type={'flat'} page_type={'list'} />} />
                            <Route path={'/property/flats/add'} element={<PropertyPage property_type={'flat'} page_type={'form'} form_type={'create'} />} />
                            <Route path={'/property/flats/:id/update'} element={<PropertyPage property_type={'flat'} page_type={'form'} form_type={'update'} />} />
                            <Route path={'/property/flats/:id/review'} element={<PropertyPage property_type={'flat'} page_type={'form'} form_type={'review'} />} />
                            <Route path={'/property/houses'} element={<PropertyPage property_type={'house'} page_type={'list'} />} />
                            <Route path={'/property/houses/add'} element={<PropertyPage property_type={'house'} page_type={'form'} form_type={'create'} />} />
                            <Route path={'/property/houses/:id/update'} element={<PropertyPage property_type={'house'} page_type={'form'} form_type={'update'} />} />
                            <Route path={'/property/houses/:id/review'} element={<PropertyPage property_type={'house'} page_type={'form'} form_type={'review'} />} />
                            <Route path={'/property/lands'} element={<PropertyPage property_type={'land'} page_type={'list'} />} />
                            <Route path={'/property/lands/add'} element={<PropertyPage property_type={'land'} page_type={'form'} form_type={'create'} />} />
                            <Route path={'/property/lands/:id/update'} element={<PropertyPage property_type={'land'} page_type={'form'} form_type={'update'} />} />
                            <Route path={'/property/lands/:id/review'} element={<PropertyPage property_type={'land'} page_type={'form'} form_type={'review'} />} />
                            <Route path={'/property/pgs'} element={<PropertyPage property_type={'pg'} page_type={'list'} />} />
                            <Route path={'/property/pgs/add'} element={<PropertyPage property_type={'pg'} page_type={'form'} form_type={'create'} />} />
                            <Route path={'/property/pgs/:id/update'} element={<PropertyPage property_type={'pg'} page_type={'form'} form_type={'update'} />} />
                            <Route path={'/property/pgs/:id/review'} element={<PropertyPage property_type={'pg'} page_type={'form'} form_type={'review'} />} />
                        </Route>
                    </Route>
                    <Route element={<PreSetupLayout />} >
                        <Route path={'/account-setup'} element={<AccountSetupPage />} />
                    </Route>
                    <Route path={'/server-error'} element={<ServerErrorPage />} />
                    <Route path={'*'} element={<NotFoundLoader />} />
                </Routes>
            </BrowserRouter>
        </UserProviderLayer>
    )
}

export default App