import { CommonAPIRequestFilter, CommonDropdownAPIRequestFilter, CommonStoreActionInterface, CommonStoreStateWithDropdownInterface, MetaAPIResponse, SelectInputItemInterface } from '../@types'
import { initialStoreState } from '../common/values'
import { AmenityAPIMapResponseInterface, AmenityAPIPayloadInterface, AmenityAPIResponseInterface, PropertyAmenityAPIPayloadInterface, PropertyAmenityAPIRequestInterface, PropertyAmenityResponseInterface } from '../models/amenity/model'
import { create } from 'zustand'
import { amenityRepository } from '../models/amenity/repository'

interface AmenityStateInterface extends CommonStoreStateWithDropdownInterface {
    amenities: AmenityAPIMapResponseInterface[]
    amenity: AmenityAPIResponseInterface
    propertyAmenity: PropertyAmenityResponseInterface[]
    meta: MetaAPIResponse
}

interface AmenityActionInterface extends CommonStoreActionInterface {
    initialState: () => void
    getAmenities: (filters: CommonAPIRequestFilter, callback?: () => void) => void
    getAmenity: (id: number, callback?: () => void) => void
    getAmenityDropdown: (filters: CommonDropdownAPIRequestFilter) => void
    getPropertyAmenity: (filters: PropertyAmenityAPIRequestInterface) => void
    postAmenity: (payload: AmenityAPIPayloadInterface, callback?: () => void) => void
    updateAmenity: (id: number, payload: AmenityAPIPayloadInterface, callback?: () => void) => void
    postPropertyAmenity: (payload: PropertyAmenityAPIPayloadInterface, callback?: () => void) => void
    destroyPropertyAmenity: (id: number, callback?: () => void) => void
}

const initial: AmenityStateInterface = {
    ...initialStoreState,
    amenities: [] as AmenityAPIMapResponseInterface[],
    amenity: {} as AmenityAPIResponseInterface,
    propertyAmenity: [] as PropertyAmenityResponseInterface[],
    meta: {} as MetaAPIResponse,
    dropdown: [] as SelectInputItemInterface[]
}

const useAmenityStore = create<AmenityStateInterface & AmenityActionInterface>((set) => ({
    ...initial,
    setLoading: (loading: boolean) => set(() => ({ loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    resetStore: () => set(() => ({ ...initial })),
    resetAlert: () => set(() => ({ success: null, error: null })),
    initialState: () => set(() => ({ loading: true, error: null })),

    getAmenities: async (filters: CommonAPIRequestFilter, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.getAmenities(filters)
        if (response.data) {
            set({ amenities: response.data.list, meta: response.data.meta })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getAmenity: async (id: number, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.getAmenity(id)
        if (response.data) {
            set({ amenity: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getAmenityDropdown: async (filters: CommonDropdownAPIRequestFilter) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.getAmenityDropdown(filters)
        if (response.data) {
            set({ dropdown: response.data.items })
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getPropertyAmenity: async (filters: PropertyAmenityAPIRequestInterface) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.getPropertyAmenity(filters)
        if (response.data) {
            set({ propertyAmenity: response.data })
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postAmenity: async (payload: AmenityAPIPayloadInterface, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.createAmenity(payload)
        if (response.data) {
            set({ amenity: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    updateAmenity: async (id: number, payload: AmenityAPIPayloadInterface, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.updateAmenity(id, payload)
        if (response.data) {
            set({ amenity: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postPropertyAmenity: async (payload: PropertyAmenityAPIPayloadInterface, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.createPropertyAmenity(payload)
        if (response.data) {
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    destroyPropertyAmenity: async (id: number, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await amenityRepository.deletePropertyAmenity(id)
        if (response.data) {
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    }
}))

export default useAmenityStore