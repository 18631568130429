import { AxiosError } from 'axios'
import { APIService } from '@bigbroindia/barebone'
import { UserRepository, UserListResponse, UserResponse, UserFindResponse } from './interface'
import { APIError, UserAPIRequestFilter, UserAPIResponse, UserFindAPIResponse, UserListAPIRequestFilter, UserListAPIResponse } from './model'
import { Repository } from '../../@types'
import { userListMap } from './mapper'

/**
 * Retrieve users list
 * @param {UserListAPIRequestFilter} filters UserListAPIRequestFilter
 * @returns {UserListResponse} UserListResponse
 */
async function getUsers (filters: UserListAPIRequestFilter): Promise<UserListResponse> {
  try {
    const response: UserListAPIResponse = await APIService.get('/a/users', filters)
    return {
      data: userListMap(response),
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('User list api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

/**
 * Retrieve user
 * @param {UserAPIRequestFilter} filters UserAPIRequestFilter
 * @returns {UserResponse} UserResponse
 */
async function getUser (id: string, filters: UserAPIRequestFilter): Promise<UserResponse> {
  try {
    const response: UserAPIResponse = await APIService.get(`/a/users/${id}`, filters)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('User api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

async function findUser (id: string): Promise<UserFindResponse> {
  try {
    const response: UserFindAPIResponse = await APIService.get(`/a/users/${id}/find`)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('User find api error response', JSON.stringify(error))
    return {
      data: null,
      error: error.message
    }
  }
}

export const userRepository: Repository<UserRepository> = {
  getUsers,
  getUser,
  findUser
}