import { Card, CardBody, CardHeader } from '@nanobits/react-ui'
import { DataTable, NButton } from '@nanobits/react-components'
import { DataTableProps } from '@nanobits/react-components/dist/react-components/src/components/smart/DataTable'
import React from 'react'

const ListBox = ({
    setCurrentPage,
    name,
    handleAddButton,
    handleRefreshButton,
    children,
    loading,
    ...rest
}: ListBoxProps) => {

    const handlePagination = (page: number) => setCurrentPage(page)

    return (
        <Card>
            <CardHeader className={'bg-primary text-white'}>
                {name}&nbsp;
                <NButton
                    className={'float-end mx-1 btn-success text-white'}
                    size={'sm'}
                    buttonIconLeft={'fa fa-square-plus'}
                    onClick={handleAddButton}
                    loading={loading}
                />
                {handleRefreshButton !== undefined && <NButton
                    className={'float-end btn-success text-white gap-3'}
                    size={'sm'}
                    buttonIconLeft={'fa fa-refresh'}
                    onClick={handleRefreshButton}
                    loading={loading}
                />}
            </CardHeader>
            <CardBody>
                <DataTable
                    onPageUpdate={handlePagination}
                    palign={'end'}
                    {...rest}
                />
            </CardBody>
            {children}
        </Card>
    )
}

export default ListBox




interface ListBoxProps extends DataTableProps {
    setCurrentPage: (value: number) => void,
    name: string,
    handleAddButton?: () => void,
    handleRefreshButton?: () => void,
    loading?: boolean,
    children?: React.ReactNode
}