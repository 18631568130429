import { Row, Col } from "@nanobits/react-ui"
import { FieldSet, SelectInput } from "../../monorep"
import { PropertyFormInterface } from "../../@types"

interface BasicInformationFieldsComponentInterface extends PropertyFormInterface {
    type?: string
    setType: (value: string) => void
}

const BasicInformationFieldsComponent = ({
    formType,
    type,
    setType
}: BasicInformationFieldsComponentInterface) => {

    return (
        <FieldSet
            label={'Basic Information'}
        >
            <Row>
                <Col md={4} className={'mb-3'} >
                    <SelectInput
                        name={'for'}
                        label={'Property For'}
                        options={[{ value: 'sale', label: 'Sale' }, { value: 'rent', label: 'Rent' }]}
                        value={type}
                        onUpdate={(value) => setType(String(value))}
                        required
                        disabled={formType === 'review'}
                    />
                </Col>
            </Row>
        </FieldSet>
    )
}

export default BasicInformationFieldsComponent