import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateStore } from '../../stores'
import { StateListAPIRequestFilter } from '../../models/state/model'
import { ListBox } from '../../components'

const StateList = () => {

    const navigate = useNavigate()

    const stateStore = useStateStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    useEffect(() => {
        const timeout = setTimeout(() => stateStore.getStates({} as StateListAPIRequestFilter))
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          indexing
          name={'States'}
          setCurrentPage={setCurrentPage}
          columns={{ name: 'Name', country: 'Country', updateOn: 'Updated On', status: 'Status' }}
          data={stateStore.states}
          meta={stateStore.meta}
          handleRefreshButton={() => stateStore.getStates({} as StateListAPIRequestFilter)}
          handleAddButton={() => navigate('/location/states/add')}
        />
    )
}

export default StateList