import { Container, Div } from '@nanobits/react-ui'
import React, { forwardRef } from 'react'
import { Slide, ToastContainer } from 'react-toastify'

import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../fallback'

interface AuthBodyProps {
    children: React.ReactNode
}

const AuthBody = forwardRef<HTMLDivElement, AuthBodyProps>((
  {
    children
  },
  ref
) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Div ref={ref} className={'nano-app nano-app-auth flex-row align-items-center'}>
        <Container className={'flex-row align-items-center'}>{children}</Container>
        <ToastContainer autoClose={5000} transition={Slide}/>
      </Div>
    </ErrorBoundary>
  )
})

export default AuthBody
