import { Col, Modal, ModalBody, ModalHeader, ModalTitle, Row } from "@nanobits/react-ui"
import { UserAPIResponse } from "../../models/user/model"
import { formatDateTime } from "@bigbroindia/helper"
import { ImageDisplay, NTextInput } from "../../monorep"

interface OwnerInformationModalInterface {
    showModal: boolean
    setShowModal: (value: boolean) => void
    user: UserAPIResponse
    setShowOptionModal?: (value: boolean) => void
}

const OwnerInformationModal = ({
    showModal,
    setShowModal,
    user,
    setShowOptionModal
}: OwnerInformationModalInterface) => {

    const handleModalClose = () => {
        setShowModal(false)
        if (setShowOptionModal) setShowOptionModal(true)
    }

    return (
        <Modal
            visible={showModal}
            onClose={handleModalClose}
            alignment={'center'}
        >
            <ModalHeader className={'bg-primary text-white'} >
                <ModalTitle>{'Owner Information'}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12} sm={12}>
                        <Row>
                            <Col md={4} sm={12} >
                                <ImageDisplay
                                    src={''}
                                />
                            </Col>
                            <Col md={8} sm={12}>
                                <Row>
                                    <Col className={'mb-3'} md={12} sm={12}>
                                        <NTextInput
                                            name={'name'}
                                            label={'Name'}
                                            value={user.name || ''}
                                            disabled
                                        />
                                    </Col>
                                    <Col className={'mb-3'} md={12}>
                                        <NTextInput
                                            name={'mobile'}
                                            label={'Mobile'}
                                            value={user.mobile || 'N/A'}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'mb-3'} md={12}>
                                <NTextInput
                                    name={'email'}
                                    label={'Email'}
                                    value={user.email || 'N/A'}
                                    disabled
                                />
                            </Col>
                            <Col md={12}>
                                <NTextInput
                                    name={'lastUpdate'}
                                    label={'Last Updated On'}
                                    value={formatDateTime(user.updatedAt) || 'N/A'}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default OwnerInformationModal