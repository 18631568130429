import { Col, Container, Row } from "@nanobits/react-ui"

import Lottie from "react-lottie-player"
import NotFound from '../../../assets/lottie/404.json'
import { useNavigate } from "react-router-dom"

const NotFoundLoader = () => {

  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <Container fluid className={'bg-primary'} onClick={handleClick}>
      <Row className={'nano-app-h-100'}>
        <Col md={6} className={'m-auto'}>
          <Lottie
            loop
            animationData={NotFound}
            play
            rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default NotFoundLoader