import { Button, Card, CardBody, CardFooter, Col, Form, FormCheck, InputGroup, Link, Row } from '@nanobits/react-ui'
import { NButton, TextInput } from '@nanobits/react-components'
import React, { forwardRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppAuth } from '../../app/hook'
import Icon from '@nanobits/react-fa-icons'
import logo from '../../assets/static/logo.svg'
import { Logo } from '@bigbroindia/logo'
import { useAuthStore } from '../../stores'

export interface LoginProps {
    google?: boolean
    facebook?: boolean
    apple?: boolean
}

const Login = forwardRef<HTMLDivElement, LoginProps>((
  {
    google,
    facebook,
    apple
  },
  ref
) => {
  const navigation = useNavigate()
  const location = useLocation()
  const auth = useAppAuth()
  const path = location.state?.from?.pathname || '/'
  const authStore = useAuthStore()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [remember, setRemember] = useState<boolean>(false)

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    auth?.login(username, password, remember, () => {
      navigation(path, {replace: true})
    })
  }

  return (
    <Row ref={ref} className={'justify-content-center'}>
      <Col md={4} sm={12}>
        <Card className={'p-4 border'}>
          <Logo image={logo} className={'nano-app-auth-logo'} height={100}/>
          <CardBody className={'p-0 mt-3'}>
            <Form onSubmit={handleSubmit}>
              <InputGroup className={'mb-3'}>
                <TextInput
                  type={'email'}
                  name={'username'}
                  iconLeft={'fa-user'}
                  placeholder={'Username'}
                  onUpdate={setUsername}
                  value={username}
                />
              </InputGroup>
              <InputGroup className={'mb-3'}>
                <TextInput
                  name={'password'}
                  iconLeft={'fa-key'}
                  type={'password'}
                  placeholder={'Password'}
                  onUpdate={setPassword}
                  value={password}
                />
              </InputGroup>
              <Row className={'mb-3'}>
                <Col md={6}>
                  <FormCheck
                    label={'Remember Me'}
                    value={remember.toString()}
                    onClick={() => setRemember(!remember)}
                  />
                </Col>
                <Col md={6} className={'text-end'}>
                  <Link className={'link'} onClick={() =>navigation('/forget-password')}>{'Forget Password'}</Link>
                </Col>
              </Row>
              <InputGroup className={'d-grid'}>
                <NButton
                  loading={authStore.loading}
                  type={'submit'}
                  buttonVariant={'outline'}
                  buttonText={'Login'}
                  disabled={!username || !password}
                />
              </InputGroup>
            </Form>
          </CardBody>
          {(google || facebook || apple) && <CardFooter className={'mt-3 bg-transparent border-top-2 text-center'}>
            {google && <Button className={'mt-2 rounded-circle google mx-2'}><Icon type={'brands'} icon={'fa-google'}/></Button>}
            {facebook && <Button className={'mt-2 rounded-circle facebook mx-2'}><Icon type={'brands'} icon={'fa-facebook'}/></Button>}
            {apple && <Button className={'mt-2 rounded-circle apple mx-2'}><Icon type={'brands'} icon={'fa-apple'}/></Button>}
          </CardFooter>}
        </Card>
      </Col>
    </Row>
  )
})

export default Login