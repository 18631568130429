import { Image } from '@nanobits/react-ui'
import React from 'react'

export const ImageDisplay = ({
    src
}:{src: string}) => {
    return (
        <Image src={createImage(src||'')} className={'img-fluid img-thumbnail'}/>
    )
}

const createImage = (url: string| undefined, height?: number, width?: number) => {
    if(url !== undefined && url !== null && url !== ''){
        return url
    }else{
        return `https://dummyimage.com/${width || 150}x${height || 150}/e6dfe6/0011ff`
    }
}