import { NavLink, useLocation } from 'react-router-dom'

import { Badge } from '@nanobits/react-ui'
import Icon from '@nanobits/react-fa-icons'
import React from 'react'

export const Menu = ({ items }: { items: any }) => {
  const location = useLocation()
  const navLink = (name: string, icon: any, badge?: any) => {
    return (
      <React.Fragment>
        {icon && (
          <Icon icon={icon.name} type={icon.type || 'solid'} className={'nav-icon'}/>
        )}
        {name && name}
        {badge && (
          <Badge color={badge.color} className={'ms-auto'}>
            {badge.text}
          </Badge>
        )}
      </React.Fragment>
    )
  }

  const navItem = (item: any, index: number) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      <Component
        {...(rest.to && !rest.items && { component: NavLink })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item: any, index: number) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item: any, index: number) => item.items ? navGroup(item, index) : navItem(item, index))}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items && items.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}