import { Sidebar, SidebarBrand, SidebarNav, SidebarToggler } from '@nanobits/react-ui'

import { Logo } from '@bigbroindia/logo'
import logo from '../../../assets/static/logo.svg'
import { Menu } from './Menu'
import { menuItems } from '../../../data'

const GlobalSidebar = () => {
  return (
    <Sidebar
      position={'fixed'}
      unfoldable={false}
      visible={true}
    >
      <SidebarBrand className={'d-none d-md-flex'}>
        <Logo image={logo} height={50} width={200}/>
      </SidebarBrand>
      <SidebarNav>
        <Menu items={menuItems} />
      </SidebarNav>
      <SidebarToggler className={'d-none d-lg-flex'}/>
    </Sidebar>
  )
}

export default GlobalSidebar