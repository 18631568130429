import { AxiosError } from 'axios'
import { FlatListResponse, FlatRepository, FlatResponse } from './interface'
import { FlatAPIPayload, FlatAPIResponse, FlatListAPIResponse, FlatReviewAPIPayload } from './model'
import { APIError, CommonAPIRequestFilter } from '../../@types'
import { APIService } from '@bigbroindia/barebone'
import { flatListMap } from './mapper'

async function getFlatsList(filter?: CommonAPIRequestFilter): Promise<FlatListResponse> {
    try {
        const response: FlatListAPIResponse = await APIService.get('/a/flats', filter)
        return {
            data: flatListMap(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Flat list api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getFlat (id: string): Promise<FlatResponse> {
    try {
        const response: FlatAPIResponse = await APIService.get(`/a/flats/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Flat api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function postFlat (payload: FlatAPIPayload): Promise<FlatResponse> {
    try {
        const response = await APIService.post('/a/flats', payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Flat post api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function putFlat (id: string, payload: FlatAPIPayload): Promise<FlatResponse> {
    try {
        const response = await APIService.put(`/a/flats/${id}`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Flat update api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function reviewFlat (id: string, payload: FlatReviewAPIPayload): Promise<FlatResponse> {
    try {
        const response = await APIService.put(`/a/flats/${id}/review`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Flat review api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const flatRepository: FlatRepository = {
    getFlatsList,
    getFlat,
    postFlat,
    putFlat,
    reviewFlat
}