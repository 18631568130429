import { Card, CardBody, CardHeader, Col, Row } from '@nanobits/react-ui'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAmenityStore, useFlatStore, useHouseStore, usePgStore } from '../../stores'
import { titleCase } from '@bigbroindia/helper'
import { NButton } from '@nanobits/react-components'
import { PropertyAmenityAPIPayloadInterface } from '../../models/amenity/model'

const AmenityAssignForm = () => {

    const { type, id } = useParams() as { type: 'flat' | 'house' | 'pg', id: string }

    const navigate = useNavigate()

    const flatStore = useFlatStore()
    const houseStore = useHouseStore()
    const pgStore = usePgStore()
    const amenityStore = useAmenityStore()

    const { flat } = flatStore
    const { house } = houseStore
    const { pg } = pgStore

    const property = {
        flat: flat,
        house: house,
        pg: pg
    }

    const handleAddAmenity = async (amenityId: string) => {
        const postData: PropertyAmenityAPIPayloadInterface = {
            property_id: id ?? '',
            property_type: type ?? '',
            amenity_id: Number(amenityId)
        }
        amenityStore.postPropertyAmenity(postData, () => {
            amenityStore.getPropertyAmenity({ property_id: postData.property_id, property_type: postData.property_type })
        })
    }

    const handleRemoveAmenity = (propertyAmenityId: number) => {
        amenityStore.destroyPropertyAmenity(propertyAmenityId, () => {
            amenityStore.getPropertyAmenity({ property_id: id ?? '', property_type: type ?? '' })
        })
    }

    useEffect(() => {
        if ((type === 'flat' && id !== undefined) && (id !== flat.id)) {
            const timeout = setTimeout(() => flatStore.getFlat(id))
            return () => clearTimeout(timeout)
        } else if ((type === 'house' && id !== undefined) && (id !== house.id)) {
            const timeout = setTimeout(() => houseStore.getHouse(id))
            return () => clearTimeout(timeout)
        } else if ((type === 'pg' && id !== undefined) && (id !== pg.id)) {
            const timeout = setTimeout(() => pgStore.getPg(id))
            return () => clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, id])

    useEffect(() => {
        if (type && id) {
            switch (type) {
                case 'flat':
                    if (id !== flat.id) {
                        navigate('/property/flats') // Navigate to the flats list
                    }
                    break
                case 'house':
                    if (id !== house.id) {
                        navigate('/property/houses') // Navigate to the houses list
                    }
                    break
                case 'pg':
                    if (id !== pg.id) {
                        navigate('/property/pgs') // Navigate to the pgs list
                    }
                    break
                default:
                    break
            }
        }
    }, [type, id, flat.id, house.id, pg.id, navigate])

    useEffect(() => {
        if (type !== undefined && id !== undefined) {
            const timeout = setTimeout(() => {
                amenityStore.getAmenityDropdown({})
                amenityStore.getPropertyAmenity({ property_id: id, property_type: type })
            })
            return () => clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, type])

    return (
        <Card>
            <CardHeader className={'bg-primary text-white'}>
                {`Assign Amenity :: ${titleCase(property[type].projectName)} (${titleCase(property[type].propertyType)})`}
                <NButton
                    className={'float-end mx-1 btn-success text-white'}
                    size={'sm'}
                    buttonIconLeft={'fa fa-bars'}
                    loading={amenityStore.loading}
                    onClick={() => navigate(`/property/${type}s`)}
                />
            </CardHeader>
            <CardBody>
                <Row>
                    {amenityStore.dropdown?.map((amenity) => {
                        const activeAmenity = amenityStore.propertyAmenity?.find((pa) => Number(amenity.value) === pa.amenityId)

                        return (
                            <Col md={4} key={amenity.value} className={'mb-2'}>
                                <NButton
                                    className={'w-100 text-white'}
                                    buttonText={titleCase(amenity?.label)}
                                    color={activeAmenity ? 'success' : 'danger'}
                                    loading={amenityStore.loading}
                                    onClick={() => {
                                        if (activeAmenity) {
                                            handleRemoveAmenity(activeAmenity.id) // Remove the active amenity
                                        } else {
                                            handleAddAmenity(amenity.value) // Add the new amenity
                                        }
                                    }}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </CardBody>
        </Card>
    )
}

export default AmenityAssignForm
