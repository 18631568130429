import { titleCase } from '@bigbroindia/helper'
import { LandAPIMap, LandAPIResponse, LandListAPIMap, LandListAPIResponse } from './model'

export const landListMap = (data: LandListAPIResponse): LandListAPIMap => {

    const { list, meta } = data

    const mappedList = list.map((item: LandAPIResponse) => {
        return {
            id: item.id,
            area: `${item.length} X ${item.width}`,
            views: item.views,
            wishlists: item.wishlists,
            city: item.city.name,
            status: titleCase(item.propertyStatus)
        } as LandAPIMap
    })

    return {
        list: mappedList,
        meta
    }
}