import { NButton } from "@nanobits/react-components"
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { useNavigate } from "react-router-dom"
import { useCityStore, useCountryStore, useStateStore } from "../../stores"
import { NTextInput, SelectInput } from "../../monorep"
import { useEffect, useState } from "react"
import { CountryDropdownAPIRequestFilter } from "../../models/country/model"
import { StateDropdownAPIRequestFilter } from "../../models/state/model"
import { CityAPIRequest } from "../../models/city/model"

interface CityFormInterface {
    formType?: 'create' | 'update'
}

const CityForm = ({
    formType
}: CityFormInterface) => {

    const navigate = useNavigate()

    const countryStore = useCountryStore()
    const stateStore = useStateStore()
    const cityStore = useCityStore()

    const [country_id, setCountryId] = useState<number>()
    const [state_id, setStateId] = useState<number>()
    const [name, setName] = useState<string>()

    const handleSubmit = () => {
        const postData: CityAPIRequest = {
            country_id: Number(country_id),
            state_id: Number(state_id),
            name: String(name)
        }

        if (formType === 'create') {
            cityStore.postCity(postData, () => navigate('/location/cities'))
        } else {
            cityStore.putCity(Number(cityStore.city.id), postData, () => navigate('/location/cities'))
        }
    }

    useEffect(() => {
        if (formType === 'update' && cityStore.city.id === undefined) {
          navigate('/location/cities')
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [cityStore.city.id, formType])

    useEffect(() => {
        const timeout = setTimeout(() => {
            countryStore.getCountryDropdown({} as CountryDropdownAPIRequestFilter)
            stateStore.getStatesDropdown({} as StateDropdownAPIRequestFilter)
        })
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (formType === 'update') {
          countryStore.getCountryDropdown({} as CountryDropdownAPIRequestFilter)
          stateStore.getStatesDropdown({} as StateDropdownAPIRequestFilter)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [formType])

    useEffect(() => {
        if (formType === 'update') {
            setCountryId(cityStore.city.countryId)
            setStateId(cityStore.city.stateId)
            setName(cityStore.city.name)
        }
      }, [cityStore, formType])

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader className={'bg-primary text-white'} >
                        {formType === 'create' ? 'Create City' : `Update City: ${cityStore.city.name}`}&nbsp;
                        <NButton
                            className={'float-end mx-1 btn-success text-white'}
                            size={'sm'}
                            buttonIconLeft={'fa fa-bars'}
                            onClick={() => navigate('/location/cities')}
                            loading={cityStore.loading}
                        />
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4} className={'mb-3'}>
                                <SelectInput
                                    name={'country_id'}
                                    label={'Country'}
                                    options={countryStore.dropdown}
                                    placeholder={'Select country'}
                                    onUpdate={(value) => setCountryId(Number(value))}
                                    required
                                    value={country_id}
                                />
                            </Col>
                            <Col md={4} className={'mb-3'}>
                                <SelectInput
                                    name={'state_id'}
                                    label={'State'}
                                    options={stateStore.dropdown}
                                    placeholder={'Select state'}
                                    onUpdate={(value) => setStateId(Number(value))}
                                    required
                                    value={state_id}
                                />
                            </Col>
                            <Col md={4} className={'mb-3'}>
                                <NTextInput
                                    name={'name'}
                                    label={'Name'}
                                    placeholder={'Enter city name'}
                                    required
                                    value={name}
                                    onUpdate={setName}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <NButton
                            className={'text-white px-4 fw-bold float-end'}
                            color={'success'}
                            buttonText={formType === 'create' ? 'Save' : 'Update'}
                            loading={stateStore.loading}
                            onClick={handleSubmit}
                        />
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default CityForm