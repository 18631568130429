import { Suspense } from "react"
import { WaitingLoader } from "../layout/theme/fallback"
import { GlobalBody } from "../layout/theme/global"
import { RoleList, UserList } from "../features"

interface UserManagementPageInterface {
    module: 'role' | 'user'
    page_type: 'list' | 'form'
    form_type?: 'create' | 'update'
}

const UserManagementPage = ({
    module,
    page_type,
    form_type
}: UserManagementPageInterface) => {
    return (
        <Suspense fallback={ <WaitingLoader/> }>
            <GlobalBody>
                { ( module === 'role' && page_type === 'list' ) && <RoleList/> }
                { ( module === 'user' && page_type === 'list' ) && <UserList/> }
            </GlobalBody>
        </Suspense>
    )
}

export default UserManagementPage