import { NButton } from '@nanobits/react-components'
import { Row, Col } from '@nanobits/react-ui'
import { OwnerInformationInterface, PropertyFormInterface } from '../../@types'
import { FieldSet, NTextInput } from '../../monorep'
import { useState } from 'react'
import OwnerInformationModal from '../../features/flat/ownerModal'

interface UserInformationInterface {
    name: string
    email: string
    mobile: string
}

interface OwnerInformationFieldsComponentInterface extends PropertyFormInterface {
    ownerInformation: OwnerInformationInterface
    userInformation?: UserInformationInterface
    ownerId?: string
    registeredBy?: string
    setOwnerId: (value: string | undefined) => void
}

const OwnerInformationFieldsComponent = ({
    formType,
    ownerInformation,
    userInformation,
    ownerId,
    registeredBy,
    setOwnerId
}: OwnerInformationFieldsComponentInterface) => {

    const [showOwnerInformationModal, setShowOwnerInformationModal] = useState<boolean>(false)

    return (
        <>
            {formType === 'review' && (ownerId !== registeredBy) && <FieldSet
                label={'Owner\'s Information'}
            >
                <Row>
                    <Col md={4} className={'mb-3'} >
                        <NTextInput
                            name={'owner_name'}
                            label={'Name'}
                            value={ownerInformation.name}
                            disabled
                        />
                    </Col>
                    <Col md={4} className={'mb-3'} >
                        <NTextInput
                            name={'email'}
                            label={'Email'}
                            value={ownerInformation.email}
                            disabled
                        />
                    </Col>
                    <Col md={4} className={'mb-3'} >
                        <NTextInput
                            name={'phone'}
                            label={'Phone'}
                            value={ownerInformation.mobile}
                            disabled
                        />
                    </Col>
                </Row>
            </FieldSet>}
            {(formType === 'create' || formType === 'review') && <FieldSet
                label={(registeredBy !== ownerId && formType === 'review') ? 'Registered By' : 'Owner Information'}
            >
                {(formType === 'create' && ownerInformation.id === undefined) && <NButton buttonText={'Search Owner'} onClick={() => setShowOwnerInformationModal(true)} />}
                {(formType === 'review' && userInformation !== undefined) &&
                    <Row>
                        <Col md={4} className={'mb-3'} >
                            <NTextInput
                                name={'owner_name'}
                                label={'Name'}
                                value={userInformation.name}
                                disabled
                            />
                        </Col>
                        <Col md={4} className={'mb-3'} >
                            <NTextInput
                                name={'email'}
                                label={'Email'}
                                value={userInformation.email}
                                disabled
                            />
                        </Col>
                        <Col md={4} className={'mb-3'} >
                            <NTextInput
                                name={'phone'}
                                label={'Phone'}
                                value={userInformation.mobile}
                                disabled
                            />
                        </Col>
                    </Row>}
                {(formType === 'create' && ownerInformation.id !== undefined) && <Row>
                    <Col md={4} className={'mb-3'} >
                        <NTextInput
                            name={'owner_name'}
                            label={'Name'}
                            value={ownerInformation.name}
                            disabled
                        />
                    </Col>
                    <Col md={4} className={'mb-3'} >
                        <NTextInput
                            name={'email'}
                            label={'Email'}
                            value={ownerInformation.email}
                            disabled
                        />
                    </Col>
                    <Col md={4} className={'mb-3'} >
                        <NTextInput
                            name={'phone'}
                            label={'Phone'}
                            value={ownerInformation.mobile}
                            disabled
                        />
                    </Col>
                </Row>}
                {(formType === 'create' && ownerInformation.id !== undefined) && <NButton className={'float-end'} buttonText={'Update Owner'} onClick={() => setShowOwnerInformationModal(true)} />}
            </FieldSet>}
            {showOwnerInformationModal && <OwnerInformationModal showModal={showOwnerInformationModal} setShowModal={setShowOwnerInformationModal} setOwnerId={setOwnerId} />}
        </>
    )
}

export default OwnerInformationFieldsComponent