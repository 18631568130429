import { Card, CardBody, Div, Progress } from "@nanobits/react-ui"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { ProgressProps } from "@nanobits/react-ui/dist/components/progress/Progress"
import styled from "styled-components"

interface ProgressWidgetInterface {
    color: 'success' | 'primary' | 'danger' | 'info' | 'warning'
    title: string
    value: string | number
    progress?: ProgressProps
    icons?: FontAwesomeIconProps
    helpertText?: string
}

const ProgressWidget = ({
    color,
    title,
    value,
    progress,
    icons,
    helpertText
}: ProgressWidgetInterface) => {

    const Small = styled.small``

    return (
        <Card
            color={color}
            textColor={'white'}
        >
            <CardBody
            >
                {icons && <Div
                  className={'mb-4 text-end'}
                >
                    <FontAwesomeIcon
                        icon={icons.icon}
                        size={'lg'}
                    />
                </Div>}
                <Div className={'fs-4 fw-semibold'}>{value}</Div>
                <Div>{title}</Div>
                {progress && <Progress
                    className={'my-2'}
                    height={4}
                    white
                    color={progress.color}
                    value={progress.value}
                />}
                { helpertText && <Small>{helpertText}</Small> }
            </CardBody>
        </Card>
    )
}

export default ProgressWidget