
export const getColorByStatus = (status: string) => {
    switch (status) {
        case 'in-review':
            return 'warning'
        case 'listed':
            return 'success'
        case 'rejected':
            return 'danger'
        default:
            return 'info'
    }
}

export const getPropertyUpdateButtonByText = (formType: 'create' | 'update' | 'review') => {
    switch(formType) {
        case 'create':
            return 'Save'
        case 'update':
            return 'Update'
        case 'review':
            return 'Approve'
        default:
            return 'Create'
    }
}

export const unixToSeconds = (unixTime: number): number => {
    const seconds = unixTime % 60
    return seconds
}