import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCityStore } from '../../stores'
import { ListBox } from '../../components'
import { CityListAPIRequestFilter } from '../../models/city/model'

const CityList = () => {

    const navigate = useNavigate()

    const cityStore = useCityStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    useEffect(() => {
        const timeout = setTimeout(() => cityStore.getCities({} as CityListAPIRequestFilter))
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          indexing
          name={'States'}
          setCurrentPage={setCurrentPage}
          columns={{ name: 'Name', state: 'State' ,country: 'Country', updateOn: 'Updated On', status: 'Status' }}
          data={cityStore.cities}
          meta={cityStore.meta}
          handleRefreshButton={() => cityStore.getCities({} as CityListAPIRequestFilter)}
          handleAddButton={() => navigate('/location/cities/add')}
        />
    )
}

export default CityList