import { Col, Row } from "@nanobits/react-ui"
import ProfileNameCard from "./ProfileName"
import PasswordChange from "./Password"
import LoginLogsCard from "./LoginLogs"

const Profile = () => {
    return (
        <>
            <Row>
                <Col className={'mb-3'} md={6} sm={12}>
                    <ProfileNameCard />
                </Col>
                <Col className={'mb-3'} md={6} sm={12}>
                    <PasswordChange />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                  <LoginLogsCard />
                </Col>
            </Row>
        </>
    )
}

export default Profile