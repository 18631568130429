import { Card, CardBody, Col, Heading, Row } from "@nanobits/react-ui"
import { ProgressWidget } from "../../monorep"
import { faBan, faCheck, faX, faClock } from "@fortawesome/free-solid-svg-icons"

const AdminDashboard = () => {
    return (
        <>
            <Row>
                <Col md={3}>
                    <ProgressWidget
                        color={'primary'}
                        title={'Unavailable Properties'}
                        value={'50 / 400'}
                        icons={{ icon: faBan }}
                        progress={{
                            color: 'white',
                            value: 75
                        }}
                    />
                </Col>
                <Col md={3}>
                    <ProgressWidget
                        color={'success'}
                        title={'Listed Properties'}
                        value={'200 / 400'}
                        icons={{ icon: faCheck }}
                        progress={{
                            color: 'white',
                            value: 75
                        }}
                    />
                </Col>
                <Col md={3}>
                    <ProgressWidget
                        color={'warning'}
                        title={'Pending Properties'}
                        value={'100 / 400'}
                        icons={{ icon: faClock }}
                        progress={{
                            color: 'primary',
                            value: 0
                        }}
                    />
                </Col>
                <Col md={3}>
                    <ProgressWidget
                        color={'danger'}
                        title={'Rejected Properties'}
                        value={'50 / 400'}
                        icons={{ icon: faX }}
                        progress={{
                            color: 'white',
                            value: 75
                        }}
                    />
                </Col>
            </Row>
            <Row className={'mt-4'} >
                <Col md={12}>
                    <Card className={'mb-4'}>
                        <CardBody>
                            <Col sm={12}>
                                <Heading size={4} className={'card-title mb-0'} >{'Top Properties'}</Heading>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AdminDashboard