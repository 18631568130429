import { Col, FormSwitch, Modal, ModalBody, ModalHeader, ModalTitle, Row } from "@nanobits/react-ui"
import { CommonModalInterface } from "../../@types"
import { useRoleStore, useUserStore } from "../../stores"
import { useEffect } from "react"

interface RoleModalInterface extends CommonModalInterface {}

const RoleModal = ({
    showModal,
    setShowModal
}: RoleModalInterface) => {

    const userStore = useUserStore()
    const roleStore = useRoleStore()

    const { userType } = userStore.user

    useEffect(() => {
        const timeout = setTimeout(() => roleStore.getRoleDropdown({ userType: userType }))
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal
          visible={showModal}
          onClose={() => setShowModal(false)}
          color={'primary'}
          size={'sm'}
        >
            <ModalHeader className={'bg-primary text-white'} >
                <ModalTitle>{`Update ${userStore.user.name}'s Roles`}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <Row>
                    { roleStore.dropdown?.map((role) => {
                        const isRoleGranted = userStore.user.roles?.find((r) => Number(role.value) === r.id) ? true : false
                        return (
                            <Col key={`role-id-${role.value}`} md={6}>
                                <FormSwitch
                                  id={role.value}
                                  label={role.label}
                                  checked={ isRoleGranted }
                                  size={'lg'}
                                />
                            </Col>
                        )
                    }) }
                  </Row>
                </Col>
              </Row>
            </ModalBody>
        </Modal>
    )
}

export default RoleModal