import { create } from 'zustand'
import { HouseAPIMAP, HouseAPIPayload, HouseAPIResponse, HouseForm, HouseReviewAPIPayload } from '../models/house/model'
import { CommonAPIRequestFilter, MetaAPIResponse } from '../@types'
import { houseRepository } from '../models/house/repository'
import { houseFormMap } from '../models/house/mapper'
import { toast } from 'react-toastify'

interface HouseStateInterface {
    loading: boolean
    error: null | string
    success: null | string
    houses: HouseAPIMAP[]
    house: HouseAPIResponse
    meta: MetaAPIResponse
    filters: CommonAPIRequestFilter
    form: HouseForm
}

interface HouseActionInterface {
    setLoading: (loading: boolean) => void
    setError: (value: string | null) => void
    setFilter: (key: keyof CommonAPIRequestFilter, value: any) => void
    clearFilter: () => void
    getHouses: (filters?: CommonAPIRequestFilter, callback?: () => void) => void
    getHouse: (id: string, callback?: () => void) => void
    postHouse: (payload: HouseAPIPayload, callback?: () => void) => void
    putHouse: (id: string, payload: HouseAPIPayload, callback?: () => void) => void
    reviewHouse: (id: string, payload: HouseReviewAPIPayload, callback?: () => void) => void
}

const initial: HouseStateInterface = {
    loading: false,
    error: null,
    success: null,
    houses: [] as HouseAPIMAP[],
    house: {} as HouseAPIResponse,
    meta: {} as MetaAPIResponse,
    filters: { page: 1, records: 10 } as CommonAPIRequestFilter,
    form: {} as HouseForm
}

const useHouseStore = create<HouseStateInterface & HouseActionInterface>((set) => ({

    ...initial,

    setLoading: (loading: boolean) => set(() => ({ loading: loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    setFilter: (key: keyof CommonAPIRequestFilter,value: string | number) => set((state) => ({ filters: {...state.filters ,[key]: value} })),
    clearFilter: () => set({ filters: {...initial.filters} }),

    getHouses: async (filters?: CommonAPIRequestFilter, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await houseRepository.getHouseList(filters)
        if (response.data) {
            set({ houses: response.data.list, meta: response.data.meta })
            if ( callback ) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getHouse: async (id: string, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await houseRepository.getHouse(id)
        if (response.data) {
            set({ house: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postHouse: async (payload: HouseAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await houseRepository.postHouse(payload)
        if (response.data) {
          set({ house: response.data })
          if (callback) callback()
        } else {
          set({ error: response.error })
        }
        set({ loading: false })
      },

      putHouse: async (id: string, payload: HouseAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await houseRepository.putHouse(id,payload)
        if (response.data) {
          set({ house: response.data, form: houseFormMap(response.data) })
          if (callback) callback()
        } else {
          set({ error: response.error })
        }
        set({ loading: false })
      },

      reviewHouse: async (id: string, payload: HouseReviewAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await houseRepository.reviewHouse(id, payload)
        if (response.data) {
          set({ success: 'houseReviewedSuccessfully' })
          toast.success('House reviewed successfully')
          if (callback) callback()
        } else {
          set({ error: response.error })
        }
        set({ loading: false })
      }
}))

export default useHouseStore