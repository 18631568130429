import { formatDateTime, titleCase } from '@bigbroindia/helper'
import { UserAPIMAPResponse, UserAPIResponse, UserListAPIMAPResponse, UserListAPIResponse } from './model'

const userListMap = (data: UserListAPIResponse): UserListAPIMAPResponse => {
  const { list, meta } = data
  const mappedList = list.map((item: UserAPIResponse, index: number) => {
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      mobile: item.mobile,
      userType: titleCase(item.userType),
      lastLogin: item.lastLogin ? formatDateTime(new Date(item.lastLogin)): '--',
      updateOn: formatDateTime(new Date(item.updatedAt)),
      status: item.status ? 'Active' : 'Suspended',
      roles: item.roles
    } as UserAPIMAPResponse
  })
  return {
    list: mappedList,
    meta
  }
}

export {
  userListMap
}