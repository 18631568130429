import { Row, Col } from '@nanobits/react-ui'
import { PropertyFormInterface } from '../../../@types'
import { DescriptionInput, FieldSet, NTextInput } from '../../../monorep'

interface PropertyInformationFieldsComponentInterface extends PropertyFormInterface {
  projectName?: string
  setProjectName: (value: string | undefined) => void
  aop?: number
  setAop: (value: number | undefined) => void
  price?: number
  setPrice: (value: number | undefined) => void
  bedroom?: number
  setBedroom: (value: number | undefined) => void
  bathroom?: number
  setBathroom: (value: number | undefined) => void
  balcony?: number
  setBalcony: (value: number | undefined) => void
  floor?: number
  setFloor: (value: number | undefined) => void
  bhk?: number
  setBhk: (value: number | undefined) => void
  carpetArea?: number
  setCarpetArea: (value: number | undefined) => void
  description?: string
  setDescription: (value: string | undefined) => void
}

const PropertyInformationFieldsComponent = ({
  formType,
  projectName,
  setProjectName,
  aop,
  setAop,
  price,
  setPrice,
  bedroom,
  setBedroom,
  bathroom,
  setBathroom,
  balcony,
  setBalcony,
  floor,
  setFloor,
  bhk,
  setBhk,
  carpetArea,
  setCarpetArea,
  description,
  setDescription
}: PropertyInformationFieldsComponentInterface) => {

  return (
    <FieldSet
      label={'Property Information'}
    >
      <Row>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'project_name'}
            label={'Project Name'}
            placeholder={'Enter project name'}
            required
            value={projectName}
            onUpdate={setProjectName}
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'aop'}
            label={'AOP'}
            placeholder={'Enter age of property'}
            required
            value={aop}
            onUpdate={setAop}
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'price'}
            label={'Price'}
            placeholder={'Enter property price'}
            required
            value={price}
            onUpdate={setPrice}
            disabled={formType === 'review'}
          />
        </Col>
      </Row>
      <Row>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'bedroom'}
            label={'Bedroom'}
            placeholder={'Enter number of bedrooms'}
            required
            value={bedroom}
            onUpdate={setBedroom}
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'bathroom'}
            label={'Bathroom'}
            placeholder={'Enter number of bathrooms'}
            required
            value={bathroom}
            onUpdate={setBathroom}
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'balcony'}
            label={'Balcony'}
            placeholder={'Enter number of balconies'}
            required
            value={balcony}
            onUpdate={setBalcony}
            disabled={formType === 'review'}
          />
        </Col>
      </Row>
      <Row>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'floor'}
            label={'Floor'}
            placeholder={'Enter flat floor'}
            required
            value={floor}
            onUpdate={setFloor}
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'bhk'}
            label={'BHK'}
            placeholder={'Enter flat BHK type'}
            required
            value={bhk}
            onUpdate={setBhk}
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={4}>
          <NTextInput
            name={'carpet_area'}
            label={'Carpet Area'}
            placeholder={'Enter flat carpet area'}
            value={carpetArea}
            onUpdate={setCarpetArea}
            disabled={formType === 'review'}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className={'mb-3'}>
          <DescriptionInput
            name={'description'}
            label={'Description'}
            placeholder={'Enter property description'}
            value={description}
            onUpdate={setDescription}
            disabled={formType === 'review'}
          />
        </Col>
      </Row>
    </FieldSet>
  )
}

export default PropertyInformationFieldsComponent