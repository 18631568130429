import { Col, Container, Paragraph, Row } from "@nanobits/react-ui"

import Lottie from "react-lottie-player"
import SecurityData from '../../../assets/lottie/SecurityData.json'
import { forwardRef } from "react"

interface SecurityLoaderProps {
  callback?: VoidFunction
}

const SecurityLoader = forwardRef<HTMLDivElement, SecurityLoaderProps>((
  { callback },
  ref
)=> {

  const handleClick = () => {
    if(callback) callback()
  }

  return (
    <Container ref={ref} fluid className={'bg-primary'} onClick={handleClick}>
      <Row className={'nano-app-h-100'}>
        <Col md={6} className={'m-auto'}>
          <Paragraph className={'text-center text-white'}>Aah!.. Sire, you are not allowed on this portal.</Paragraph>
          <Lottie
            loop
            animationData={SecurityData}
            play
            rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}}
          />
        </Col>
      </Row>
    </Container>
  )
}
)
export default SecurityLoader