import React from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppAuth } from '../app/hook'
import { UserProvider } from '../app/user'
import { UserAPIResponse } from '../models/user/model'
import { useAuthStore } from '../stores'
import { LogoutAPIRequest } from '../models/auth/model'
import { SecurityLoader } from '../layout/theme/fallback'
import { StorageService } from '@bigbroindia/barebone'
interface AuthenticProps {
  children: JSX.Element
}

export const Authentic = ({children}: AuthenticProps) => {
  const auth = useAppAuth()
  const location = useLocation()
  const navigation = useNavigate()

  const logMeOut = () => auth?.logout({ type: 'single' }, () => navigation('/login', { replace: true }))
  if (!auth?.token) return <Navigate to={'/login'} state={{ from: location }} replace />
  if (auth.token && auth.user?.userType !== 'admin') return <SecurityLoader callback={logMeOut}/>
  if (!auth?.isProfileCompleted) return <Navigate to={'/account-setup'} state={{ from: location }} replace />
  return children
}

export const PreAuthentic = ({children}: AuthenticProps) => {
  const auth = useAppAuth()
  if (auth?.token) return <Navigate to={'/'} replace />
  return children
}

export const PreSetup = ({children}: AuthenticProps) => {
  const auth = useAppAuth()
  const location = useLocation()
  if (!auth?.token) return <Navigate to={'/login'} state={{ from: location }} replace />
  if (auth?.isProfileCompleted && auth?.token) return <Navigate to={'/'} replace />
  return children
}

export const AuthenticLayout = () => {
  return (
    <Authentic>
      <Outlet/>
    </Authentic>
  )
}

export const PreAuthenticLayout = () => {
  return (
    <PreAuthentic>
      <Outlet/>
    </PreAuthentic>
  )
}

export const PreSetupLayout = () => {
  return (
    <PreSetup>
      <Outlet/>
    </PreSetup>
  )
}

export const UserProviderLayer = ({ children }: { children: React.ReactNode }) => {
  const authStore = useAuthStore()

  const user = authStore.profile as UserAPIResponse
  const token = StorageService.getItem('token') as string
  const refresh = StorageService.getItem('refresh') as string

  const isProfileCompleted = user?.lastname !== null && (user?.mobile !== null && user?.phoneVerifiedAt !== null)

  const login = async (username: string, password: string ,remember: boolean, callback: VoidFunction) => {
    authStore.postLogin({ username, password, remember}, () => profile(() => {callback()}))
  }
  const logout = async (payload: LogoutAPIRequest, callback: VoidFunction) => {
    authStore.postLogout(payload, callback)
  }
  const profile = async (callback: VoidFunction) => {
    authStore.getProfile(callback)
  }
  return <UserProvider value={{user, token, refresh, isProfileCompleted, login, logout, profile}}>{children}</UserProvider>
}