import { useNavigate } from "react-router-dom"
import { useCityStore, usePgStore, useStateStore, useUserStore } from "../../stores"
import { useEffect, useState } from "react"
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { NButton } from "@nanobits/react-components"
import { DescriptionInput, FieldSet, NTextInput } from "../../monorep"
import { PgAPIPayload } from "../../models/pgs/model"
import { getPropertyUpdateButtonByText } from "../../helpers/shorthand"
import { OwnerInformationInterface, PropertyFormInterface } from "../../@types"
import { LocationInformationFieldsComponent, OwnerInformationFieldsComponent } from "../../components"

const PgForm = ({
    formType = 'create'
}: PropertyFormInterface) => {

    const navigate = useNavigate()

    const pgStore = usePgStore()
    const stateStore = useStateStore()
    const cityStore = useCityStore()
    const userStore = useUserStore()

    const [state_id, setStateId] = useState<number>()
    const [city_id, setCityId] = useState<number>()
    const [address, setAddress] = useState<string>()
    const [rent, setRent] = useState<number>()
    const [security, setSecurity] = useState<number>()
    const [seater, setSeater] = useState<number>()
    const [mess, setMess] = useState<boolean>(false)
    const [parking, setParking] = useState<boolean>(false)
    const [description, setDescription] = useState<string | undefined>()
    const [project_name, setProjectName] = useState<string>()
    const [ownerId, setOwnerId] = useState<string>()
    const [ownerInformation, setOwnerInformation] = useState<OwnerInformationInterface>({} as OwnerInformationInterface)

    const submitAction = () => navigate('/property/pgs')

    const handleSubmit = () => {
        const postData: PgAPIPayload = {
            city_id: Number(city_id),
            address: String(address),
            rent: Number(rent),
            security: Number(security),
            seater: Number(seater),
            mess,
            parking,
            description: String(description),
            project_name: String(project_name)
        }

        if (formType === 'create') {
            pgStore.postPg({ ...postData, owner_id: String(ownerId) }, submitAction)
        } else {
            pgStore.putPg(String(pgStore.pg.id), postData, submitAction)
        }
    }

    const handleReviewAction = (action: 'approved' | 'rejected') => {
        pgStore.reviewPg(String(pgStore.pg.id), { status: action === 'approved' ? null : 'rejected' }, submitAction)
    }

    useEffect(() => {
        if ((formType === 'update' || formType === 'review') && pgStore.pg.id === undefined) {
            navigate('/property/pgs')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pgStore.pg.id, formType])

    useEffect(() => {
        const timeout = setTimeout(() => stateStore.getStatesDropdown({ country_id: 1 }))
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (formType === 'update' || formType === 'review') {
            cityStore.getCitiesDropdown({ state_id: state_id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_id, formType])

    useEffect(() => {
        if ((formType === 'update' || formType === 'review') && pgStore.pg.id !== undefined) {
            setStateId(pgStore.pg.city.stateId)
            setCityId(pgStore.pg.cityId)
            setAddress(pgStore.pg.address)
            setRent(pgStore.pg.rent)
            setSecurity(pgStore.pg.security)
            setSeater(pgStore.pg.seater)
            setMess(pgStore.pg.mess)
            setParking(pgStore.pg.parking)
            setDescription(String(pgStore.pg.description))
            setProjectName(pgStore.pg.projectName)
        }
    }, [pgStore, formType])

    useEffect(() => {
        if (formType === 'review' && pgStore.pg.id !== undefined && pgStore.pg.ownerId !== pgStore.pg.registeredBy) {
            userStore.getUser(pgStore.pg.ownerId, () => {
                setOwnerInformation({ id: userStore.user.id, name: userStore.user.name, email: userStore.user?.email, mobile: userStore.user?.mobile })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pgStore, formType])

    useEffect(() => {
        if (formType === 'create') {
            if (ownerId === userStore.owner.id) {
                setOwnerInformation(userStore.owner)
            } else {
                setOwnerInformation({} as OwnerInformationInterface)
            }
        }
    }, [formType, ownerId, userStore.owner])

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader className={'bg-primary text-white'} >
                        {formType === 'create' ? 'Create Pg' : `Update Pg: ${pgStore.pg.projectName}`}&nbsp;
                        <NButton
                            className={'float-end mx-1 btn-success text-white'}
                            size={'sm'}
                            buttonIconLeft={'fa fa-bars'}
                            onClick={() => navigate('/property/pgs')}
                            loading={pgStore.loading}
                        />
                    </CardHeader>
                    <CardBody>
                        <LocationInformationFieldsComponent
                          formType={formType}
                          state_id={state_id}
                          setStateId={setStateId}
                          city_id={city_id}
                          setCityId={setCityId}
                          address={address}
                          setAddress={setAddress}
                        />
                        <FieldSet
                            label={'Property Information'}
                        >
                            <Row>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'project_name'}
                                        label={'Project Name'}
                                        placeholder={'Enter project name'}
                                        required
                                        value={project_name}
                                        onUpdate={setProjectName}
                                    />
                                </Col>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'rent'}
                                        label={'Rent'}
                                        placeholder={'Enter property rent'}
                                        required
                                        value={rent}
                                        onUpdate={setRent}
                                    />
                                </Col>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'security'}
                                        label={'Security'}
                                        placeholder={'Enter pg security here'}
                                        required
                                        value={security}
                                        onUpdate={setSecurity}
                                    />
                                </Col>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'seater'}
                                        label={'Seater'}
                                        placeholder={'Pg seater option'}
                                        required
                                        value={seater}
                                        onUpdate={setSeater}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className={'mb-3'}>
                                    <DescriptionInput
                                        disabled={formType === 'review'}
                                        name={'description'}
                                        label={'Description'}
                                        placeholder={'Enter property description'}
                                        value={description}
                                        onUpdate={setDescription}
                                    />
                                </Col>
                            </Row>
                        </FieldSet>
                        <FieldSet
                            label={'Amenities Information'}
                        >
                            <Row>
                                <Col md={6} className={'mb-3'}>
                                    <NButton
                                        disabled={formType === 'review'}
                                        buttonText={'Parking'}
                                        color={parking ? 'success' : 'danger'}
                                        className={`text-white w-100 ${parking ? '' : 'text-decoration-line-through'}`}
                                        onClick={() => setParking((value) => !value)}
                                    />
                                </Col>
                                <Col md={6} className={'mb-3'}>
                                    <NButton
                                        disabled={formType === 'review'}
                                        buttonText={'Mess'}
                                        color={mess ? 'success' : 'danger'}
                                        className={`text-white w-100 ${mess ? '' : 'text-decoration-line-through'}`}
                                        onClick={() => setMess((value) => !value)}
                                    />
                                </Col>
                            </Row>
                        </FieldSet>
                        <OwnerInformationFieldsComponent
                            formType={formType}
                            ownerInformation={ownerInformation}
                            userInformation={pgStore.pg.user}
                            ownerId={pgStore.pg.ownerId}
                            registeredBy={pgStore.pg.registeredBy}
                            setOwnerId={setOwnerId}
                        />
                    </CardBody>
                    <CardFooter>
                        <NButton
                            className={'text-white px-4 mx-1 fw-bold float-end'}
                            color={'success'}
                            buttonText={getPropertyUpdateButtonByText(formType)}
                            loading={pgStore.loading}
                            onClick={formType === 'review' ? () => handleReviewAction('approved') : handleSubmit}
                            disabled={formType === 'create' && ownerInformation.id === undefined}
                        />
                        {formType === 'review' && <NButton
                            className={'text-white px-4 fw-bold float-end'}
                            color={'danger'}
                            buttonText={'Reject'}
                            loading={pgStore.loading}
                            onClick={() => handleReviewAction('rejected')}
                        />}
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default PgForm