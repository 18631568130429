import { Row, Col } from '@nanobits/react-ui'
import { PropertyFormInterface } from '../../@types'
import { DescriptionInput, FieldSet, SelectInput } from '../../monorep'
import { useCityStore, useStateStore } from '../../stores'

interface LocationInformationFieldsComponentInterface extends PropertyFormInterface {
  state_id?: number
  setStateId: (value: number | undefined) => void
  city_id?: number
  setCityId: (value: number | undefined) => void
  address?: string
  setAddress: (value: string | undefined) => void
}

const LocationInformationFieldsComponent = ({
  formType,
  state_id,
  city_id,
  address,
  setStateId,
  setCityId,
  setAddress
}: LocationInformationFieldsComponentInterface) => {

  const stateStore = useStateStore()
  const cityStore = useCityStore()

  const handleSelectState = (value: number) => {
    setCityId(undefined)
    setStateId(value)
    cityStore.getCitiesDropdown({ state_id: value })
  }

  return (
    <FieldSet
      label={'Location Information'}
    >
      <Row>
        <Col className={'mb-3'} md={6}>
          <SelectInput
            name={'state'}
            label={'State'}
            placeholder={'Select state'}
            options={stateStore.dropdown}
            value={state_id}
            onUpdate={(value) => handleSelectState(Number(value))}
            required
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={6}>
          <SelectInput
            name={'city'}
            label={'City'}
            placeholder={'Select city'}
            options={cityStore.dropdown}
            onUpdate={(value) => setCityId(Number(value))}
            value={city_id}
            required
            disabled={formType === 'review'}
          />
        </Col>
        <Col className={'mb-3'} md={12}>
          <DescriptionInput
            name={'address'}
            label={'Address'}
            placeholder={'Enter property address'}
            value={address}
            onUpdate={setAddress}
            required
            disabled={formType === 'review'}
          />
        </Col>
      </Row>
    </FieldSet>
  )
}

export default LocationInformationFieldsComponent