import { create } from 'zustand'
import { authRepository } from '../models/auth/repository'
import { StorageService } from '@bigbroindia/barebone'
import { LogoutAPIRequest, OtpResndAPIRequest, OtpSendAPIRequest, OtpSendApiResponse, PasswordChangeAPIRequest, PasswordForgetAPIRequest, PasswordForgetAPIRespone, PasswordResetAPIRequest, ProfileAPIRequest, ProfileAPIResponse, VerifyOtpAPIRequest } from '../models/auth/model'
import { UploadAPIResponse } from '../models/upload/model'

interface AuthStateInterface {
  loading: boolean
  error: null | string
  success: null | string,
  token: null | string,
  refresh: null | string,
  profile: ProfileAPIResponse,
  progress: number
  files: UploadAPIResponse[] | null
  otpPayload: OtpSendApiResponse | null
}

interface LoginRequest {
  username: string
  password: string
  remember: boolean
}

interface AuthActionInterface {
  setLoading: (loading: boolean) => void
  setError: (value: string | null) => void
  getProfile: (callback: () => void) => void
  postLogin: (payload: LoginRequest, callback: () => void) => void
  postPasswordForget: (payload: PasswordForgetAPIRequest, callback: (data: PasswordForgetAPIRespone) => void) => void
  postPasswordReset: (payload: PasswordResetAPIRequest, callback: () => void) => void
  postProfile: (payload: ProfileAPIRequest, callback: () => void) => void
  postPasswordChange: (payload: PasswordChangeAPIRequest, callback: () => void) => void
  postLogout: (payload: LogoutAPIRequest, callback: () => void) => void
  postSendOtp: (payload: OtpSendAPIRequest, callback?: () => void) => void
  postSessionLogout: (payload: LogoutAPIRequest, callback?: () => void) => void
  postResendOtp: (payload: OtpResndAPIRequest, callback?: () => void) => void
  postOtpVerification: (payload: VerifyOtpAPIRequest, callback?: () => void) => void
  resetStore: () => void
  resetAlert: () => void
}

const initial = {
  loading: false,
  error: null,
  success: null,
  progress: 0,
  files: null,
  token: StorageService.getItem('token'),
  refresh: StorageService.getItem('refresh'),
  profile: StorageService.getItem('user'),
  otpPayload: {} as OtpSendApiResponse
}

const useAuthStore = create<AuthStateInterface & AuthActionInterface>((set) => ({
  ...initial,

  setLoading: (loading: boolean) => set(() => ({ loading: loading })),
  setError: (value: string | null) => set(() => ({ error: value })),
  resetStore: () => set(() => (initial)),
  resetAlert: () => set(() => ({ success: null, error: null })),

  /**
   * Post login action
   * @param {LoginRequest} payload LoginRequest
   * @param {()=>void} callback () => void
   */
  postLogin: async (payload: LoginRequest, callback: () => void) => {
    set({ loading: true, error: null, success: null })
    StorageService.setStore(payload.remember)
    const response = await authRepository.postLogin({ username: payload.username, password: payload.password })
    if (response.data) {
      StorageService.setItem('token', response.data.token)
      StorageService.setItem('refresh', response.data.refresh)
      set({ token: response.data.token, refresh: response.data.refresh, success: 'loginSuccess' })
      callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  /**
   * Get profile action
   * @param {()=>void} callback () => void
   */
  getProfile: async (callback: () => void) => {
    set({ loading: true, error: null, success: null })
    const response = await authRepository.getProfile()
    if (response.data) {
      StorageService.setItem('user', response.data)
      set({ profile: response.data })
      callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  /**
   * Post password forget action
   * @param {PasswordForgetAPIRequest} payload PasswordForgetAPIRequest
   * @param {(data: PasswordForgetAPIRespone) => void} callback () => void
   */
  postPasswordForget: async (payload: PasswordForgetAPIRequest, callback: (data: PasswordForgetAPIRespone) => void) => {
    set({ loading: true, success: null, error: null })
    const response = await authRepository.postPasswordForget({ username: payload.username })
    if (response.data) {
      set({ success: response.data.message })
      callback(response.data)
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  /**
   * Post password reset action
   * @param {PasswordResetAPIRequest} payload PasswordResetAPIRequest
   * @param {() => void} callback () => void
   */
  postPasswordReset: async (payload: PasswordResetAPIRequest, callback: () => void) => {
    set({ loading: true, success: null, error: null })
    const response = await authRepository.postPasswordReset({ id: payload.id, code: payload.code, password: payload.password })
    if (response.data) {
      set({ success: response.data.message })
      callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  /**
   * Post profile save action
   * @param {ProfileAPIRequest} payload ProfileAPIRequest
   * @param {() => void} callback () => void
   */
  postProfile: async (payload: ProfileAPIRequest, callback: () => void) => {
    set({ loading: true, success: null, error: null })
    const response = await authRepository.postProfile(payload)
    if (response.data) {
      set({ profile: response.data, success: 'profileUpdatedSuccess' })
      callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  /**
   * Post password change action
   * @param {PasswordChangeAPIRequest} payload PasswordChangeAPIRequest
   * @param {() => void} callback () => void
   */
  postPasswordChange: async (payload: PasswordChangeAPIRequest, callback: () => void) => {
    set({ loading: true, success: null, error: null })
    const response = await authRepository.postPasswordChange(payload)
    if (response.data) {
      set({ success: response.data.message })
      callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  /**
   * POst logout action
   * @param {LogoutAPIRequest} payload LogoutAPIRequest
   * @param {() => void} callback () => void
   */
  postLogout: async (payload: LogoutAPIRequest, callback: () => void) => {
    set({ loading: true, success: null, error: null })
    const response = await authRepository.postLogout(payload)
    if (response.data) {
      StorageService.clearAll()
      set({ success: response.data.message, token: null, refresh: null })
      callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  postSessionLogout: async (payload: LogoutAPIRequest, callback?: () => void) => {
    set({ loading: true, success: null, error: null })
    const response = await authRepository.postLogout(payload)
    if (response.data) {
      set({ success: response.data.message, token: null, refresh: null })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  postSendOtp: async (payload: OtpSendAPIRequest, callback?: () => void) => {
    set({ loading: true, error: null, success: null })
    const response = await authRepository.postOtpSend(payload)
    if (response.message) {
      set({ success: 'otpSend', otpPayload: response.message })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  postResendOtp: async (payload: OtpResndAPIRequest, callback?: () => void) => {
    set({ loading: true, error: null, success: null })
    const response = await authRepository.postOtpResend(payload)
    if (response.message) {
      set({ success: 'otpSend', otpPayload: response.message })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },

  postOtpVerification: async (payload: VerifyOtpAPIRequest, callback?: () => void) => {
    set({ loading: true, error: null, success: null })
    const response = await authRepository.postOtpVerification(payload)
    if (response.data) {
      set({ success: 'otpVerifiedSuccessfully' })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  }
}))

export default useAuthStore