import { useNavigate } from 'react-router-dom'
import { useAmenityStore } from '../../stores'
import { useEffect, useState } from 'react'
import { CommonMetaData } from '../../data'
import { ListBox } from '../../components'
import { NDataOptionModal } from '../../monorep'
import { titleCase } from '@bigbroindia/helper'

const AmenityList = () => {

    const amenityStore = useAmenityStore()
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)

    const handleRowClick = async (id: number) => {
        amenityStore.getAmenity(id, () => {
            setShowOptionModal(true)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => amenityStore.getAmenities({ page: currentPage, records: CommonMetaData.recordsPerPage }))

        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    return (
        <ListBox
            indexing
            name={'Amenities'}
            setCurrentPage={setCurrentPage}
            columns={{ name: 'Name', status: 'Status', updatedOn: 'Updated On' }}
            data={amenityStore.amenities}
            meta={amenityStore.meta}
            primary={['id']}
            handleAddButton={() => navigate('/amenities/add')}
            onRowClick={(data: any) => handleRowClick(data.id)}
        >
            {showOptionModal && <NDataOptionModal
                visible={showOptionModal}
                setVisible={setShowOptionModal}
                primary={amenityStore.amenity.id}
                options={[
                    [
                        { value: titleCase(amenityStore.amenity.status ? 'Active' : 'Not Active'), color: amenityStore.amenity.status ? 'success' : 'danger' }
                    ],
                    [
                        { title: 'Update', color: 'info', icon: 'fa-pencil', onClick: (id: number) => navigate('/amenities/:id/update'.replace(':id', String(id))) }
                    ]
                ]}
            />}
        </ListBox>
    )
}

export default AmenityList