import { Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row } from "@nanobits/react-ui"
import { useRoleStore } from "../../stores"
import { DescriptionInput, NTextInput, SelectInput } from "../../monorep"
import { RolePostAPIPayload } from "../../models/roles/model"
import { useEffect, useState } from "react"
import { NButton } from "@nanobits/react-components"

interface RoleFormInterface {
    formType: 'create' | 'update'
    showModal: boolean
    setShowModal: (value: boolean) => void
    setAddNewRecord: (value: boolean) => void
}
const RoleForm = ({
    formType = 'create',
    showModal,
    setShowModal,
    setAddNewRecord
}: RoleFormInterface) => {

    const roleStore = useRoleStore()

    const [name, setName] = useState<string>('')
    const [userType, setUserType] = useState<string | number>()
    const [description, setDescription] = useState<string>()

    const handleClose = (refreshList: boolean) => {
        setShowModal(false)
        if (formType === 'create') setAddNewRecord(false)
        if (refreshList) {
            formType === 'create' ? roleStore.setCurrentPage(1) : roleStore.getRoles({ page: roleStore.currentPage, records: 10 })
        }
    }

    const handleSubmit = () => {
        const payload: RolePostAPIPayload = {
            name: name,
            userType: String(userType),
            description: description,
            roleType: 'user generated'
        }
        if (formType === 'create') {
            roleStore.postRole(payload, () => handleClose(true))
        } else {
            roleStore.updateRole(roleStore.role.id, payload, () => handleClose(true))
        }
    }

    useEffect(() => {
        if (formType === 'update') {
            setName(roleStore.role.name)
            setUserType(roleStore.role.userType)
            setDescription(roleStore.role.description)
        }
    }, [roleStore.role, formType])

    return (
        <Modal
            visible={showModal}
            onClose={() => handleClose(false)}
            color={'primary'}
            backdrop={'static'}
        >
            <ModalHeader
                closeButton
                className={'bg-primary text-white'}
            >
                <ModalTitle>{formType === 'create' ? 'Create Role' : `Update Role: ${roleStore.role.name}`}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                      <Row>
                        <Col className={'mb-3'} md={12} sm={12}>
                          <NTextInput
                            name={'name'}
                            label={'Name'}
                            placeholder={'Enter role name'}
                            value={name}
                            onUpdate={setName}
                            required
                          />
                        </Col>
                        <Col className={'mb-3'} md={12} sm={12}>
                          <SelectInput
                            name={'user_type'}
                            label={'User Type'}
                            placeholder={'Select role user type'}
                            onUpdate={(value) => setUserType(value)}
                            value={userType}
                            options={[
                                {
                                    label: 'Admin',
                                    value: 'admin'
                                },
                                {
                                    label: 'Owner',
                                    value: 'owner'
                                },
                                {
                                    label: 'Buyer',
                                    value: 'buyer'
                                }
                            ]}
                            required
                          />
                        </Col>
                        <Col className={'mb-3'} md={12} sm={12}>
                          <DescriptionInput
                            name={'description'}
                            label={'Description'}
                            value={description}
                            onUpdate={setDescription}
                            placeholder={'Enter role description'}
                          />
                        </Col>
                      </Row>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <NButton
                  buttonText={formType === 'create' ? 'Create' : 'Update'}
                  onClick={handleSubmit}
                  loading={roleStore.loading}
                />
            </ModalFooter>
        </Modal>
    )
}

export default RoleForm