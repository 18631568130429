import { useEffect, useState } from 'react'
import { ListBox, OwnerInformationModal } from '../../components'
import { NDataOptionModal } from '../../monorep'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { useLandStore, useUserStore } from '../../stores'
import { useNavigate } from 'react-router-dom'

const LandList = () => {

    const navigate = useNavigate()

    const userStore = useUserStore()
    const landStore = useLandStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)
    const [showOwnerInformationModal, setShowOwnerInformationModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        landStore.getLand(id, () => {
            setShowOptionModal(true)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => landStore.getLands())
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
            name={'Lands'}
            setCurrentPage={setCurrentPage}
            columns={{ city: 'City', area: 'Size', views: 'Views', wishlists: 'Wishlists', status: 'Status' }}
            data={landStore.lands}
            meta={landStore.meta}
            primary={['id']}
            handleRefreshButton={() => landStore.getLands()}
            onRowClick={(data: any) => handleRowClick(data.id)}
            handleAddButton={() => navigate('/property/lands/add')}
        >
            {showOptionModal && <NDataOptionModal
                visible={showOptionModal}
                setVisible={setShowOptionModal}
                primary={landStore.land.id}
                options={
                    [
                        [
                            { value: titleCase(landStore.land.propertyStatus), color: getColorByStatus(landStore.land.propertyStatus), disabled: true }
                        ],
                        [
                            { title: landStore.land.propertyStatus === 'in-review' ? 'Review' : 'Update', color: 'info', icon: 'fa-pencil', onClick: (id: string) => landStore.land.propertyStatus === 'in-review' ? navigate('/property/lands/:id/review'.replace(':id', id)) : navigate('/property/lands/:id/update'.replace(':id', id)) },
                            {
                                title: 'View Owner Information', color: 'warning', icon: 'fa-eye', onClick: (id: string) => {
                                    userStore.getUser(landStore.land.ownerId, () => {
                                        setShowOwnerInformationModal(true)
                                        setShowOptionModal(false)
                                    })
                                }
                            }
                        ],
                        [
                            {
                                title: 'Amenities', 
                                color: 'danger', icon: 'fa-bars',
                                onClick: (id: string) => navigate(`/amenities/land/${id}/assign`)
                            }
                        ]
                    ]
                }
            />}
            {showOwnerInformationModal && <OwnerInformationModal
                showModal={showOwnerInformationModal}
                setShowModal={setShowOwnerInformationModal}
                user={userStore.user}
                setShowOptionModal={setShowOptionModal}
            />}
        </ListBox>
    )
}

export default LandList