import { Div, Footer, Link } from '@nanobits/react-ui'

const GlobalFooter = () => {

  return (
    <Footer>
      <Div>
        <Link href={'https://doc.vidhyasaga.com'} className={'text-decoration-none'} target={'_blank'} rel={'noopener noreferrer'}>{'Bigbro Client'}</Link>
        &nbsp;&reg; v{process.env.REACT_APP_VERSION}
      </Div>
    </Footer>
  )
}

export default GlobalFooter