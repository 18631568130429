import { useEffect, useState } from 'react'
import { useUserStore } from '../../stores'
import { ListBox } from '../../components'
import { NDataOptionModal } from '../../monorep'
import RoleModal from './role-modal'

const UserList = () => {

    const userStore = useUserStore()

    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)

    const [showRoleModal, setShowRoleModal] = useState<boolean>(false)

    const handleRowClick = (id: string) => {
        userStore.getUser(id, () => {
            setShowOptionModal(true)
        })
    }

    const handleRoleModalButtom = () => {
        setShowOptionModal(false)
        setShowRoleModal(true)
    }

    useEffect(() => {
        const timeout = setTimeout(() => userStore.getUsers({ page: currentPage, records: 10 }))
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    return (
        <ListBox
            name={'Users'}
            setCurrentPage={setCurrentPage}
            columns={{ name: 'Name', userType: 'User\'s Type', email: 'Email', mobile: 'Mobile', lastLogin: 'Last Login On', updateOn: 'Last Updated On' }}
            data={userStore.users}
            meta={userStore.meta}
            indexing
            primary={['id']}
            handleRefreshButton={() => userStore.getUsers({ page: currentPage, records: 10 })}
            onRowClick={(data: any) => handleRowClick(data.id)}
        >
            {showOptionModal && <NDataOptionModal
                visible={showOptionModal}
                setVisible={setShowOptionModal}
                primary={userStore.user.id}
                options={[
                    [
                        {
                            title: 'Roles',
                            color: 'primary',
                            icon: 'fa-eye',
                            onClick: () => handleRoleModalButtom()
                        },
                        {
                            title: 'Permissions',
                            color: 'info',
                            icon: 'fa-eye',
                            onClick: () => { }
                        }
                    ],
                    [
                        {
                            title: 'User Logs',
                            color: 'warning',
                            icon: 'fa-eye',
                            onClick: () => { }
                        },
                        {
                            title: 'Change Password',
                            color: 'danger',
                            icon: 'fa-eye',
                            onClick: () => { }
                        }
                    ]
                ]}
            />}
            {showRoleModal && <RoleModal
                showModal={showRoleModal}
                setShowModal={setShowRoleModal}
            />}
        </ListBox>
    )
}

export default UserList