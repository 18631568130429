import { APIService } from '@bigbroindia/barebone'
import { PermissionDropdownResponse, PermissionRepository } from './interface'
import { AxiosError } from 'axios'
import { APIError } from '../user/model'

async function getPermissionDropdown (): Promise<PermissionDropdownResponse> {
    try {
        const response = await APIService.get('/a/permissions/dropdown')
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Roles list api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const permissionRepository: PermissionRepository = {
    getPermissionDropdown
}