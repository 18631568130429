import { useEffect, useState } from "react"
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { NButton } from "@nanobits/react-components"
import { useStateStore, useCityStore, useHouseStore, useUserStore } from "../../stores"
import { useNavigate } from "react-router-dom"
import { HouseAPIPayload } from "../../models/house/model"
import { getPropertyUpdateButtonByText } from "../../helpers/shorthand"
import { AmenitiesInformationFieldsComponent, BasicInformationFieldsComponent, LocationInformationFieldsComponent, OwnerInformationFieldsComponent } from "../../components"
import { OwnerInformationInterface, PropertyFormInterface } from "../../@types"
import PropertyInformationFieldsComponent from "./component/Property"

const HouseForm = ({
  formType = 'create'
}: PropertyFormInterface) => {

  const navigate = useNavigate()

  const stateStore = useStateStore()
  const cityStore = useCityStore()
  const houseStore = useHouseStore()
  const userStore = useUserStore()

  const [type, setType] = useState<string>()
  const [address, setAddress] = useState<string>()
  const [price, setPrice] = useState<number>()
  const [aop, setAop] = useState<number>()
  const [bedroom, setBedroom] = useState<number>()
  const [bathroom, setBathroom] = useState<number>()
  const [balcony, setBalcony] = useState<number>()
  const [floor, setFloor] = useState<number>()
  const [description, setDescription] = useState<string | undefined>()
  const [carpet_area, setCarpetArea] = useState<number>()
  const [project_name, setProjectName] = useState<string>()
  const [ownerId, setOwnerId] = useState<string>()
  const [ownerInformation, setOwnerInformation] = useState<OwnerInformationInterface>({} as OwnerInformationInterface)


  const [state_id, setStateId] = useState<number>()
  const [city_id, setCityId] = useState<number>()

  const [furnishing, setFurnishing] = useState<'unfurnished' | 'semi-furnished' | 'furnished'>('unfurnished')
  const [bachelor_friendly, setBachelorFriendely] = useState<boolean>(false)
  const [parking, setParking] = useState<boolean>(false)

  const submitAction = () => navigate('/property/houses')

  const handleSubmit = async () => {
    const postData: HouseAPIPayload = {
      city_id: Number(city_id),
      address: String(address),
      price: Number(price),
      furnishing,
      parking,
      aop: Number(aop),
      bedroom: Number(bedroom),
      bathroom: Number(bathroom),
      balcony: Number(balcony),
      floors: Number(floor),
      description: String(description),
      carpet_area: Number(carpet_area),
      project_name: String(project_name),
      type: String(type),
      bachelor_friendly
    }
    if (formType === 'create') {
      houseStore.postHouse({...postData, owner_id: ownerId}, submitAction)
    } else {
      houseStore.putHouse(houseStore.house.id, postData, submitAction)
    }
  }

  const handleReviewAction = (action: 'approved' | 'rejected') => {
    houseStore.reviewHouse(String(houseStore.house.id), { status: action === 'approved' ? null : 'rejected' }, submitAction)
  }

  useEffect(() => {
    if ((formType === 'update' || formType === 'review') && houseStore.house.id === undefined) {
      navigate('/property/houses')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseStore.house.id, formType])

  useEffect(() => {
    const timeout = setTimeout(() => stateStore.getStatesDropdown({ country_id: 1 }))
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formType === 'update' || formType === 'review') {
      cityStore.getCitiesDropdown({ state_id: state_id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id, formType])

  useEffect(() => {
    if (formType === 'update' || formType === 'review') {
      setFurnishing(houseStore.house.furnishing)
      setBachelorFriendely(houseStore.house.bachelorFriendly)
      setParking(houseStore.house.parking)
      setType(houseStore.house.type)
      setStateId(houseStore.house.city.stateId)
      setCityId(houseStore.house.cityId)
      setAddress(houseStore.house.address)
      setProjectName(houseStore.house.projectName)
      setAop(Number(houseStore.house.aop))
      setPrice(Number(houseStore.house.price))
      setBathroom(houseStore.house.bathroom)
      setBedroom(houseStore.house.bedroom)
      setBalcony(houseStore.house.balcony)
      setFloor(houseStore.house.floors)
      setCarpetArea(Number(houseStore.house.carpetArea))
      setDescription(String(houseStore.house.description))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseStore, formType])

  useEffect(() => {
    if (formType === 'review' && houseStore.house.id !== undefined && houseStore.house.ownerId !== houseStore.house.registeredBy) {
      userStore.getUser(houseStore.house.ownerId, () => {
        setOwnerInformation({ id: userStore.user.id, name: userStore.user.name, email: userStore.user?.email, mobile: userStore.user?.mobile })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseStore, formType])

  useEffect(() => {
    if (formType === 'create') {
      if (ownerId === userStore.owner.id) {
        setOwnerInformation(userStore.owner)
      } else {
        setOwnerInformation({} as OwnerInformationInterface)
      }
    }
  }, [formType, ownerId, userStore.owner])

  return (
    <Row>
      <Col md={12}>
        <Card
        >
          <CardHeader className={'bg-primary text-white'} >
            {`${(formType === 'create' && 'Create') || (formType === 'update' && 'Update') || (formType === 'review' && 'Review')} ${formType === 'create' ? 'House' : `House : ${houseStore.house.projectName}`}`}&nbsp;
            <NButton
              className={'float-end mx-1 btn-success text-white'}
              size={'sm'}
              buttonIconLeft={'fa fa-bars'}
              onClick={() => navigate('/property/houses')}
              loading={houseStore.loading}
            />
          </CardHeader>
          <CardBody>
            <BasicInformationFieldsComponent
              formType={formType}
              type={type}
              setType={setType}
            />
            <LocationInformationFieldsComponent
              formType={formType}
              state_id={state_id}
              setStateId={setStateId}
              city_id={city_id}
              setCityId={setCityId}
              address={address}
              setAddress={setAddress}
            />
            <PropertyInformationFieldsComponent
              formType={formType}
              projectName={project_name}
              setProjectName={setProjectName}
              aop={aop}
              setAop={setAop}
              price={price}
              setPrice={setPrice}
              bedroom={bedroom}
              setBedroom={setBedroom}
              bathroom={bathroom}
              setBathroom={setBathroom}
              balcony={balcony}
              setBalcony={setBalcony}
              floor={floor}
              setFloor={setFloor}
              carpetArea={carpet_area}
              setCarpetArea={setCarpetArea}
              description={description}
              setDescription={setDescription}
            />
            <AmenitiesInformationFieldsComponent
              formType={formType}
              bachelorFriendly={bachelor_friendly}
              setBachelorFriendly={setBachelorFriendely}
              furnishing={furnishing}
              setFurnishing={setFurnishing}
              parking={parking}
              setParking={setParking}
            />
            <OwnerInformationFieldsComponent
              formType={formType}
              ownerInformation={ownerInformation}
              userInformation={houseStore.house.user}
              ownerId={houseStore.house.ownerId}
              registeredBy={houseStore.house.registeredBy}
              setOwnerId={setOwnerId}
            />
          </CardBody>
          <CardFooter>
            <NButton
              className={'text-white px-4 mx-1 fw-bold float-end'}
              color={'success'}
              buttonText={getPropertyUpdateButtonByText(formType)}
              loading={houseStore.loading}
              onClick={formType === 'review' ? () => handleReviewAction('approved') : handleSubmit}
              disabled={formType === 'create' && ownerInformation.id === undefined}
            />
            {formType === 'review' && <NButton
              className={'text-white px-4 fw-bold float-end'}
              color={'danger'}
              buttonText={'Reject'}
              loading={houseStore.loading}
              onClick={() => handleReviewAction('rejected')}
            />}
          </CardFooter>
        </Card>
      </Col>
    </Row>
  )
}

export default HouseForm