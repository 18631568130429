import { AxiosError } from 'axios'
import { APIService } from '@bigbroindia/barebone'
import { Repository } from '../../@types'
import { APIError, CityAPIRequest, CityAPIRequestFilter, CityAPIResponse, CityDropdownAPIRequestFilter, CityDropdownAPIResponse, CityListAPIRequestFilter, CityListAPIResponse, CityStatusAPIResponse } from './model'
import { CityDropdownResponse, CityListResponse, CityRepository, CityResponse, CityStausResponse } from './interface'
import { cityDropdownMap, cityListMap } from './mapper'

async function getCities (filters: CityListAPIRequestFilter): Promise<CityListResponse> {
  try {
    const response: CityListAPIResponse = await APIService.get('/a/cities', filters)
    return {
      data: cityListMap(response),
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('City list api response error', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function getCity(id: number, filters: CityAPIRequestFilter): Promise<CityResponse> {
  try {
    const response: CityAPIResponse = await APIService.get(`/a/cities/${id}`, filters)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('City api response error', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function getCityDropdown(filters: CityDropdownAPIRequestFilter): Promise<CityDropdownResponse> {
  try {
    const response: CityDropdownAPIResponse = await APIService.get('/a/cities/dropdown', filters)
    return {
      data: cityDropdownMap(response),
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('City Dropdown api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function postCity(payload: CityAPIRequest): Promise<CityResponse>{
  try {
    const response: CityAPIResponse = await APIService.post('/a/cities', payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('City post api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function putCity(id: number,payload: CityAPIRequest): Promise<CityResponse>{
  try {
    const response: CityAPIResponse = await APIService.put(`/a/cities/${id}`, payload)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('City update api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

async function patchCity(id: number): Promise<CityStausResponse>{
  try {
    const response: CityStatusAPIResponse = await APIService.patch(`/a/cities/${id}/status`)
    return {
      data: response,
      error: null
    }
  } catch (err) {
    const error = (err as AxiosError).response?.data as APIError
    console.error('City update api error response', error)
    return {
      data: null,
      error: error.message
    }
  }
}

export const cityRepository: Repository<CityRepository> = {
  getCities,
  getCity,
  getCityDropdown,
  postCity,
  putCity,
  patchCity
}