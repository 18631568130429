import { create } from 'zustand'
import { SelectInputItemInterface } from '../@types'
import { MetaAPIResponse, StateAPIMAPResponse, StateAPIRequest, StateAPIRequestFilter, StateAPIResponse, StateDropdownAPIRequestFilter, StateForm, StateListAPIRequestFilter } from '../models/state/model'
import { stateRepository } from '../models/state/repository'
import { stateFormMap } from '../models/state/mapper'

interface StateStaesInterface {
  loading: boolean
  error: null|string
  success: string|null
  state: StateAPIResponse
  states: StateAPIMAPResponse[]
  dropdown: SelectInputItemInterface[]
  meta: MetaAPIResponse
  form: StateForm
}

interface StateActionInterface {
  setLoading: (loading: boolean) => void
  setError: (value: string | null) => void
  resetStore: () => void
  resetAlert: () => void
  getStates: (filters: StateListAPIRequestFilter, callback?: () => void) => void
  getState: (id: number,filters: StateAPIRequestFilter, callback?: () => void) => void
  getStatesDropdown: (filters: StateDropdownAPIRequestFilter, callback?: () => void) => void
  postStates: (payload: StateAPIRequest, callback?: () => void) => void
  putState: (id: number, payload: StateAPIRequest, callback?: () => void) => void
  patchState: (id: number, callback?: () => void) => void
}

const initial: StateStaesInterface = {
  loading: false as boolean,
  error: null,
  success: null,
  meta: {} as MetaAPIResponse,
  form: {} as StateForm,
  state: {} as StateAPIResponse,
  states: [] as StateAPIMAPResponse[],
  dropdown: [] as SelectInputItemInterface[]
}

const useStateStore = create<StateStaesInterface & StateActionInterface>((set) => ({
  ...initial,
  setLoading: (loading: boolean) => set(() => ({ loading: loading })),
  setError: (value: string | null) => set(() => ({ error: value })),
  resetStore: () => set(() => (initial)),
  resetAlert: () => set(() => ({ success: null, error: null })),
  
  getStates: async (filters: StateListAPIRequestFilter, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await stateRepository.getStates(filters)
    if (response.data) {
      set({ states: response.data.list, meta: response.data.meta })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  getState: async (id: number, filters: StateAPIRequestFilter, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await stateRepository.getState(id, filters)
    if (response.data) {
      set({ state: response.data, form: stateFormMap(response.data) })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  getStatesDropdown: async (filters: StateDropdownAPIRequestFilter, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await stateRepository.getStateDropdown(filters)
    if (response.data) {
      set({ dropdown: response.data.items })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  postStates: async ( payload: StateAPIRequest, callback?: () => void ) => {
    set({ loading: true, error: null })
    const response = await stateRepository.postState(payload)
    if (response.data) {
      set({ success: 'stateCreatedSuccess' })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  putState: async (id: number, payload: StateAPIRequest, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await stateRepository.putState(id, payload)
    if (response.data) {
      set({ success: 'stateUpdatedSuccess' })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  },
  patchState: async (id: number, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await stateRepository.patchState(id)
    if (response.data) {
      set({ success: response.data.message })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  }
}))

export default useStateStore