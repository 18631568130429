import React from 'react'
import { UserAPIResponse } from '../models/user/model'
import { LogoutAPIRequest } from '../models/auth/model'

export interface AuthInterface {
  user: UserAPIResponse
  token: string
  refresh: string
  isProfileCompleted: boolean
  login: (user: string, password: string, remember: boolean, callback: VoidFunction) => void
  logout: (payload: LogoutAPIRequest, callback: VoidFunction) => void
  profile: (callback: VoidFunction) => void
}

const UserContext = React.createContext<null|AuthInterface>(null)
export const UserProvider = UserContext.Provider

export { UserContext as default }