import { Form, InputGroup } from "@nanobits/react-ui"
import { NTextInput } from "../../monorep"
import { useState } from "react"
import { NButton } from "@nanobits/react-components"
import { useAuthStore } from "../../stores"

const NameForm = () => {

    const authStore = useAuthStore()

    const [ firstname, setFirstName ] = useState<string>('')
    const [ lastname, setLastName ] = useState<string>()

    const handleSubmit = () => {
        authStore.postProfile({ firstname, lastname }, () => {})
    }

    return (
        <Form>
            <InputGroup className={'mb-3'}>
              <NTextInput
                type={'text'}
                name={'firstname'}
                value={firstname}
                placeholder={'Firstname'}
                onUpdate={setFirstName}
              />
            </InputGroup>
            <InputGroup className={'mb-3'}>
              <NTextInput
                type={'text'}
                name={'Lastname'}
                value={lastname}
                placeholder={'lastname'}
                onUpdate={setLastName}
              />
            </InputGroup>
            <InputGroup className={'d-grid'}>
                <NButton
                  loading={authStore.loading}
                  buttonVariant={'outline'}
                  buttonText={'Update'}
                  disabled={!firstname || !lastname}
                  onClick={handleSubmit}
                />
              </InputGroup>
        </Form>
    )
}

export default NameForm