import { NButton } from "@nanobits/react-components"
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from "@nanobits/react-ui"
import { FieldSet, NTextInput } from "../../monorep"
import { useNavigate } from "react-router-dom"
import { useCityStore, useLandStore, useStateStore, useUserStore } from "../../stores"
import { useEffect, useState } from "react"
import { LandAPIPayload } from "../../models/land/model"
import { getPropertyUpdateButtonByText } from "../../helpers/shorthand"
import { LocationInformationFieldsComponent, OwnerInformationFieldsComponent } from "../../components"
import { OwnerInformationInterface, PropertyFormInterface } from "../../@types"

const LandForm = ({
    formType = 'create'
}: PropertyFormInterface) => {

    const navigate = useNavigate()

    const landStore = useLandStore()
    const stateStore = useStateStore()
    const cityStore = useCityStore()
    const userStore = useUserStore()

    const [state_id, setStateId] = useState<number>()
    const [city_id, setCityId] = useState<number>()
    const [address, setAddress] = useState<string>()
    const [price, setPrice] = useState<number>()
    const [open_side, setOpenSide] = useState<number>()
    const [length, setLength] = useState<number>()
    const [width, setWidth] = useState<number>()
    const [area, setArea] = useState<number>()
    const [ownerId, setOwnerId] = useState<string>()
    const [ownerInformation, setOwnerInformation] = useState<OwnerInformationInterface>({} as OwnerInformationInterface)

    const submitAction = () => navigate('/property/lands')

    const handleSubmit = () => {
        const postData: LandAPIPayload = {
            city_id: Number(city_id),
            address: String(address),
            price: Number(price),
            length: Number(length),
            width: Number(width),
            area: Number(area),
            open_side: Number(open_side)
        }
        if (formType === 'create') {
            landStore.postLand({...postData, owner_id: String(ownerId)}, submitAction)
        } else {
            landStore.putLand(String(landStore.land.id), postData, submitAction)
        }
    }

    const handleReviewAction = (action: 'approved' | 'rejected') => {
        landStore.reviewLand(String(landStore.land.id), { status: action === 'approved' ? null : 'rejected' }, submitAction)
    }

    useEffect(() => {
        if ((formType === 'update' || formType === 'review') && landStore.land.id === undefined) {
            navigate('/property/lands')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [landStore.land.id, formType])

    useEffect(() => {
        const timeout = setTimeout(() => stateStore.getStatesDropdown({ country_id: 1 }))
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (formType === 'update' || formType === 'review') {
            cityStore.getCitiesDropdown({ state_id: state_id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_id, formType])

    useEffect(() => {
        if ((formType === 'update' || formType === 'review') && landStore.land.id !== undefined) {
            setStateId(landStore.land.city.stateId)
            setCityId(landStore.land.cityId)
            setAddress(landStore.land.address)
            setLength(landStore.land.length)
            setWidth(landStore.land.width)
            setOpenSide(landStore.land.openSide)
            setPrice(landStore.land.price)
            setArea(landStore.land.area)
        }
    }, [landStore, formType])

    useEffect(() => {
        if (formType === 'review' && landStore.land.id !== undefined && landStore.land.ownerId !== landStore.land.registeredBy) {
            userStore.getUser(landStore.land.ownerId, () => {
                setOwnerInformation({ id: userStore.user.id, name: userStore.user.name, email: userStore.user?.email, mobile: userStore.user?.mobile })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [landStore, formType])

    useEffect(() => {
        if (formType === 'create') {
            if (ownerId === userStore.owner.id) {
                setOwnerInformation(userStore.owner)
            } else {
                setOwnerInformation({} as OwnerInformationInterface)
            }
        }
    }, [formType, ownerId, userStore.owner])

    return (
        <Row>
            <Col md={12}>
                <Card
                >
                    <CardHeader className={'bg-primary text-white'} >
                        {`${(formType === 'create' && 'Create') || (formType === 'update' && 'Update') || (formType === 'review' && 'Review')} Land `}&nbsp;
                        <NButton
                            className={'float-end mx-1 btn-success text-white'}
                            size={'sm'}
                            buttonIconLeft={'fa fa-bars'}
                            onClick={() => navigate('/property/lands')}
                            loading={landStore.loading}
                        />
                    </CardHeader>
                    <CardBody>
                        <LocationInformationFieldsComponent
                            formType={formType}
                            state_id={state_id}
                            setStateId={setStateId}
                            city_id={city_id}
                            setCityId={setCityId}
                            address={address}
                            setAddress={setAddress}
                        />
                        <FieldSet
                            label={'Basic Information'}
                        >
                            <Row>
                                <Col md={4} className={'mb-3'}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'length'}
                                        label={'Length'}
                                        required
                                        placeholder={'Enter land length'}
                                        value={length}
                                        onUpdate={setLength}
                                    />
                                </Col>
                                <Col md={4} className={'mb-3'}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'width'}
                                        label={'Width'}
                                        required
                                        placeholder={'Enter land width'}
                                        value={width}
                                        onUpdate={setWidth}
                                    />
                                </Col>
                                <Col md={4} className={'mb-3'}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'area'}
                                        label={'Area'}
                                        required
                                        placeholder={'Enter land area'}
                                        value={area}
                                        onUpdate={setArea}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'mb-3'} md={6}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'price'}
                                        label={'Price'}
                                        required
                                        placeholder={'Enter land price'}
                                        value={price}
                                        onUpdate={setPrice}
                                    />
                                </Col>
                                <Col className={'mb-3'} md={6}>
                                    <NTextInput
                                        disabled={formType === 'review'}
                                        name={'open_side'}
                                        label={'Open Side'}
                                        required
                                        placeholder={'Enter land number of open sides'}
                                        value={open_side}
                                        onUpdate={setOpenSide}
                                    />
                                </Col>
                            </Row>
                        </FieldSet>
                        <OwnerInformationFieldsComponent
                            formType={formType}
                            ownerInformation={ownerInformation}
                            userInformation={landStore.land.user}
                            ownerId={landStore.land.ownerId}
                            registeredBy={landStore.land.registeredBy}
                            setOwnerId={setOwnerId}
                        />
                    </CardBody>
                    <CardFooter>
                        <NButton
                            className={'text-white px-4 mx-1 fw-bold float-end'}
                            color={'success'}
                            buttonText={getPropertyUpdateButtonByText(formType)}
                            loading={landStore.loading}
                            onClick={formType === 'review' ? () => handleReviewAction('approved') : handleSubmit}
                            disabled={formType === 'create' && ownerInformation.id === undefined}
                        />
                        {formType === 'review' && <NButton
                            className={'text-white px-4 fw-bold float-end'}
                            color={'danger'}
                            buttonText={'Reject'}
                            loading={landStore.loading}
                            onClick={() => handleReviewAction('rejected')}
                        />}
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default LandForm