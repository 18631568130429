import { NavGroup, NavItem, NavTitle } from "@nanobits/react-ui"

const menuItems = [
    {
        component: NavItem,
        name: "Dashboard",
        to: '/'
    },
    // {
    //     component: NavItem,
    //     name: "Category",
    //     to: '/categories'
    // },
    {
        component: NavItem,
        name: "Amenity",
        to: '/amenities'
    },
    {
        component: NavGroup,
        name: 'Location',
        to: '/locations',
        items: [
            {
                component: NavItem,
                name: 'State',
                to: '/location/states'
            },
            {
                component: NavItem,
                name: 'City',
                to: '/location/cities'
            }
        ]
    },
    {
        component: NavGroup,
        name: 'Property',
        to: '/property',
        items: [
            {
                component: NavItem,
                name: 'Flats',
                to: '/property/flats'
            },
            {
                component: NavItem,
                name: 'Houses',
                to: '/property/houses'
            },
            {
                component: NavItem,
                name: 'Lands',
                to: '/property/lands'
            },
            {
                component: NavItem,
                name: 'PGs',
                to: '/property/pgs'
            }
        ]
    },
    {
        component: NavTitle,
        name: 'User Management'
    },
    {
        component: NavItem,
        name: 'Roles',
        to: '/roles'
    },
    {
        component: NavItem,
        name: 'Users',
        to: '/users'
    }
]

export default menuItems