import { forwardRef } from 'react'
import BugLoader from './BugErrorLoader'

interface ErrorFallbackProps {
    error: any,
    resetErrorBoundary: any
}

const ErrorFallback = forwardRef<HTMLDivElement, ErrorFallbackProps>((
  {
    error,
    resetErrorBoundary
  },
  ref
) => {
  return (
    <BugLoader message={error.message} />
  )
})

export default ErrorFallback