import { useEffect, useState } from 'react'
import { ListBox } from '../../components'
import RoleForm from './formModal'
import { useRoleStore } from '../../stores'
import { NDataOptionModal } from '../../monorep'
import PermissionModal from './permissions'

const RoleList = () => {

    const roleStore = useRoleStore()

    const [showRoleForm, setShowRoleForn] = useState<boolean>(false)
    const [addNewRecord, setAddNewRecord] = useState<boolean>(false)
    const [resetCurrentPage, setResetCurrentPage] = useState<boolean>(true)
    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)
    const [showPermissionModal, setShowPermissionModal] = useState<boolean>(false)

    const handleRowClick = (id: number) => {
        roleStore.getRole(id, () => {
            setShowOptionModal(true)
        })
    }

    const handleAddButton = () => {
        setAddNewRecord(true)
        setShowRoleForn(true)
    }

    const handleUpdateButton = () => {
        setAddNewRecord(false)
        setShowRoleForn(true)
        setShowOptionModal(false)
    }

    const handlePermissionButton = () => {
        setShowOptionModal(false)
        setShowPermissionModal(true)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (resetCurrentPage) {
                roleStore.setCurrentPage(1)
                if (roleStore.currentPage === 1) roleStore.getRoles({ page: roleStore.currentPage, records: 10 })
                setResetCurrentPage(false)
            } else {
                roleStore.getRoles({ page: roleStore.currentPage, records: 10 })
            }
        })
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleStore.currentPage])

    return (
        <ListBox
          name={'Roles'}
          setCurrentPage={roleStore.setCurrentPage}
          columns={{ name: 'Name', userType: 'User\'s Type', roleType: 'Role\'s Type' ,createdAt: 'Created On', updatedAt: 'Updated On' }}
          data={roleStore.roles}
          meta={roleStore.meta}
          primary={['id']}
          handleRefreshButton={() => roleStore.getRoles({ page: roleStore.currentPage, records: 10 })}
          onRowClick={(data: any) => handleRowClick(data.id)}
          handleAddButton={() => handleAddButton()}
        >
            {showOptionModal && <NDataOptionModal
                visible={showOptionModal}
                setVisible={setShowOptionModal}
                primary={roleStore.role.id}
                options={[
                    [
                        {
                            title: 'Update',
                            color: 'primary',
                            icon: 'fa-pencil',
                            onClick: () => handleUpdateButton(),
                            disabled: roleStore.role.roleType === 'default'
                        },
                        {
                            title: 'Permissions',
                            color: 'info',
                            icon: 'fa-eye',
                            onClick: () => handlePermissionButton(),
                            disabled: roleStore.role.userType === 'admin' && roleStore.role.roleType === 'default'
                        }
                    ]
                ]}
            />}
            {showRoleForm && <RoleForm
              formType={ addNewRecord ? 'create' : 'update' }
              showModal={showRoleForm}
              setShowModal={setShowRoleForn}
              setAddNewRecord={setAddNewRecord}
            />}
            {showPermissionModal && <PermissionModal
                showModal={showPermissionModal}
                setShowModal={setShowPermissionModal}
            />}
        </ListBox>
    )
}

export default RoleList