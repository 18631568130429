import { titleCase } from '@bigbroindia/helper'
import { NButton } from '@nanobits/react-components'
import { Row, Col } from '@nanobits/react-ui'
import { PropertyFormInterface } from '../../@types'
import { FieldSet } from '../../monorep'
import { useEffect, useState } from 'react'

interface AmenitiesInformationFieldsComponentInterface extends PropertyFormInterface {
    bachelorFriendly: boolean
    setBachelorFriendly: (value: boolean) => void
    furnishing: string
    setFurnishing: (value: 'unfurnished' | 'semi-furnished' | 'furnished') => void
    parking: boolean
    setParking: (value: boolean) => void
}

const AmenitiesInformationFieldsComponent = ({
    formType,
    bachelorFriendly,
    setBachelorFriendly,
    furnishing,
    setFurnishing,
    parking,
    setParking
}: AmenitiesInformationFieldsComponentInterface) => {

    const [isBachelorFriendly, setIsBachelorFriendly] = useState<boolean>(bachelorFriendly)
    const [isParking, setIsParking] = useState<boolean>(parking)

    const handleBachelorFrindly = () => {
        setIsBachelorFriendly((value) => !value)
    }

    const handleParking = () => {
        setIsParking((value) => !value)
    }

    const handleFurnishingOption = (option: 'color' | 'value', type: typeof furnishing): any => {
        switch (type) {
            case 'furnished':
                if (option === 'color') return 'success'
                setFurnishing('unfurnished')
                break
            case 'unfurnished':
                if (option === 'color') return 'danger'
                setFurnishing('semi-furnished')
                break
            case 'semi-furnished':
                if (option === 'color') return 'warning'
                setFurnishing('furnished')
                break
            default:
                break
        }
    }

    useEffect(() => {
        setBachelorFriendly(isBachelorFriendly)
    }, [isBachelorFriendly, setBachelorFriendly])

    useEffect(() => {
        setParking(isParking)
    }, [isParking, setParking])

    return (
        <FieldSet
            label={'Amenities Information'}
        >
            <Row>
                <Col md={4} className={'mb-3'}>
                    <NButton
                        buttonText={'Bachelor Friendly'}
                        color={isBachelorFriendly ? 'success' : 'danger'}
                        className={`text-white w-100 ${isBachelorFriendly ? '' : 'text-decoration-line-through'}`}
                        onClick={handleBachelorFrindly}
                        disabled={formType === 'review'}
                    />
                </Col>
                <Col md={4} className={'mb-3'}>
                    <NButton
                        className={'w-100 text-white'}
                        buttonText={titleCase(furnishing)}
                        color={handleFurnishingOption('color', furnishing)}
                        onClick={() => handleFurnishingOption('value', furnishing)}
                        disabled={formType === 'review'}
                    />
                </Col>
                <Col md={4} className={'mb-3'}>
                    <NButton
                        buttonText={'Parking'}
                        color={isParking ? 'success' : 'danger'}
                        className={`text-white w-100 ${isParking ? '' : 'text-decoration-line-through'}`}
                        onClick={handleParking}
                        disabled={formType === 'review'}
                    />
                </Col>
            </Row>
        </FieldSet>
    )
}

export default AmenitiesInformationFieldsComponent