import { create } from 'zustand'
import { SelectInputItemInterface } from '../@types'
import { CountryDropdownAPIRequestFilter } from '../models/country/model'
import { countryRepository } from '../models/country/repository'

interface CountryStateInterface {
  loading: boolean
  error: null|string
  success: string|null
  dropdown: SelectInputItemInterface[]
}

interface CountryActionInterface {
  setLoading: (loading: boolean) => void
  setError: (value: string | null) => void
  resetStore: () => void
  resetAlert: () => void
  getCountryDropdown: (filters: CountryDropdownAPIRequestFilter, callback?: () => void) => void
}

const initial: CountryStateInterface = {
  loading: false as boolean,
  error: null,
  success: null,
  dropdown: [] as SelectInputItemInterface[]
}

const useCountryStore = create<CountryStateInterface & CountryActionInterface>((set) => ({
  ...initial,
  setLoading: (loading: boolean) => set(() => ({ loading: loading })),
  setError: (value: string | null) => set(() => ({ error: value })),
  resetStore: () => set(() => (initial)),
  resetAlert: () => set(() => ({ success: null, error: null })),
  
  getCountryDropdown: async (filters: CountryDropdownAPIRequestFilter, callback?: () => void) => {
    set({ loading: true, error: null })
    const response = await countryRepository.getCountryDropdown(filters)
    if (response.data) {
      set({ dropdown: response.data.items })
      if (callback) callback()
    } else {
      set({ error: response.error })
    }
    set({ loading: false })
  }
}))

export default useCountryStore