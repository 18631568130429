import { Col, FormCheck, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row, Span, Tooltip } from "@nanobits/react-ui"
import { usePermissionStore, useRoleStore } from "../../stores"
import { useEffect, useState } from "react"
import getFormattedPermission from "../../helpers/permission-transformer"
import { titleCase } from "@bigbroindia/helper"
import { NButton } from "@nanobits/react-components"

interface PermissionModalInterface {
    showModal: boolean
    setShowModal: (value: boolean) => void
}

const PermissionModal = ({
    showModal,
    setShowModal
}: PermissionModalInterface) => {

    const permissionStore = usePermissionStore()
    const roleStore = useRoleStore()

    const [rolePermissions, setRolePermissions] = useState<number[]>([])
    const [newRolePermissions, setNewRolePermissions] = useState<number[]>([])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.currentTarget.value)
        const isChecked = event.currentTarget.checked

        const updatedRolePermissions = isChecked ? [...rolePermissions, value] : rolePermissions.filter(item => item !== value)

        setRolePermissions(updatedRolePermissions)
        setNewRolePermissions(updatedRolePermissions)
    }
    
    const handelUpdateButton = () => {
        const payload: { permissionIds: number[] } = {
            permissionIds: newRolePermissions
        }
        roleStore.postRolePermissions(roleStore.role.id, payload, () => setShowModal(false))
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            permissionStore.getPermissionDropdown()
            roleStore.getRolePermissions(roleStore.role.id)
        })
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (roleStore.rolePermissions.length !== 0) {
            setRolePermissions(roleStore.rolePermissions)
        }
    }, [roleStore.rolePermissions])

    return (
        <Modal
            visible={showModal}
            onClose={() => setShowModal(false)}
        >
            <ModalHeader
                className={'bg-primary text-white'}
            >
                <ModalTitle>{'Permissions'}</ModalTitle>
            </ModalHeader>
            <ModalBody
                className={'long-modal-body'}
            >
                <Row className={'my-2'}>
                    {getFormattedPermission(permissionStore.permissionsDropdown)?.map((permission) => {
                        return (
                            <Col md={12} className={'mb-1 border py-1'} key={`permission-group-${permission.name}`}>
                                <Row>
                                    <Col md={12}>
                                        <Span className={'fw-bold'} >{permission.name.toUpperCase()}</Span>
                                        <Col md={12}>
                                            <Row>
                                                {permission.permissions.map(gp => {
                                                    return (
                                                        <Col md={4} key={`gp-item-${gp.id}`}>
                                                            <Tooltip content={`${gp.description} permission`}>
                                                                <FormCheck
                                                                    id={`permission-id-${gp.id}`}
                                                                    label={titleCase(gp.name)}
                                                                    value={gp.id}
                                                                    checked={rolePermissions.includes((typeof gp.id === 'number') ? gp.id : parseInt(gp.id))}
                                                                    onChange={handleChange}
                                                                />
                                                            </Tooltip>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
            </ModalBody>
            <ModalFooter>
                <NButton
                    buttonText={'Update'}
                    onClick={handelUpdateButton}
                />
            </ModalFooter>
        </Modal>
    )
}

export default PermissionModal