import { AxiosError } from 'axios';
import { APIError, CommonAPIRequestFilter } from '../../@types'
import { LandListResponse, LandRepository, LandResponse } from './interface';
import { LandAPIPayload, LandListAPIResponse, LandReviewAPIPayload } from './model';
import { APIService } from '@bigbroindia/barebone';
import { landListMap } from './mapper';

async function getLands (filters?: CommonAPIRequestFilter): Promise<LandListResponse> {
    try {
        const response: LandListAPIResponse = await APIService.get('/a/lands', filters)
        return {
            data: landListMap(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Land list api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getLand (id: string): Promise<LandResponse> {
    try {
        const response = await APIService.get(`/a/lands/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Land api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function postLand (payload: LandAPIPayload) : Promise<LandResponse> {
    try {
        const response = await APIService.post('/a/lands', payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Land post api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function putLand (id: string, payload: LandAPIPayload) : Promise<LandResponse> {
    try {
        const response = await APIService.put(`/a/lands/${id}`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Land api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function reviewLand (id: string, payload: LandReviewAPIPayload) : Promise<LandResponse> {
    try {
        const response = await APIService.put(`/a/lands/${id}/review`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Land review api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const landRepository: LandRepository = {
    getLands,
    getLand,
    postLand,
    putLand,
    reviewLand
}