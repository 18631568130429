import { titleCase } from '@bigbroindia/helper'
import { HouseAPIMAP, HouseAPIResponse, HouseListAPIMap, HouseListAPIResponse, HouseForm } from './model'

export const houseListMap = (data: HouseListAPIResponse): HouseListAPIMap => {

    const { list, meta } = data

    const mappedList = list.map((item: HouseAPIResponse) => {
        return {
            id: item.id,
            name: item.projectName !== null ? titleCase(item.projectName) : 'N/A',
            furnishing: titleCase(item.furnishing),
            views: item.views,
            wishlists: item.wishlists,
            city: item.city.name,
            status: titleCase(item.propertyStatus),
            for: titleCase(item.type)
        } as HouseAPIMAP
    })

    return {
        list: mappedList,
        meta
    }
}

export const houseFormMap = (data: HouseAPIResponse): HouseForm => {
    return {
      city_id: String(data.cityId) ?? null,
      address: data.address ?? null,
      price: data.price ?? null,
      furnishing: data.furnishing ?? null,
      parking: data.parking ?? null,
      aop: data.aop ?? null,
      bedroom: String(data.bedroom) ?? null,
      bathroom: String(data.bathroom) ?? null,
      balcony: String(data.balcony) ?? null,
      floors: String(data.floors) ?? null,
      description: data.description ?? undefined,
      project_name: data.projectName ?? null,
      carpet_area: data.carpetArea ?? null,
      type: data.type ?? null,
      bachelor_friendly: data.bachelorFriendly ?? null,
      country_id: String(data.city.countryId) ?? null,
      state_id: String(data.city.stateId) ?? null
    }
}